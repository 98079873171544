import React from "react";

const RewardCoinIcon = (props) => {
  return (
    <svg
      {...props}
      width={props?.width ?? "99"}
      height={props?.height ?? "100"}
      viewBox="0 0 99 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.5"
        y="2"
        width="96"
        height="96"
        rx="48"
        fill="#FFF1A9"
        stroke="#FCDB00"
        strokeWidth="3"
      />
      <rect
        x="15.5"
        y="16"
        width="68"
        height="68"
        rx="34"
        fill="#FFF7CB"
        stroke="#FCDB00"
        strokeWidth="3"
      />
      <g clipPath="url(#clip0_50_3471)">
        <path
          d="M50.4749 36.8381L53.5601 44.1906C53.6348 44.3691 53.7568 44.5237 53.9129 44.638C54.069 44.7522 54.2533 44.8216 54.446 44.8388L62.343 45.5214C62.5525 45.5383 62.7524 45.6169 62.9172 45.7473C63.082 45.8778 63.2045 46.0542 63.269 46.2542C63.3336 46.4543 63.3373 46.669 63.2798 46.8712C63.2223 47.0734 63.1061 47.254 62.946 47.3901L56.9548 52.6163C56.8092 52.7442 56.7009 52.9092 56.6415 53.0937C56.5821 53.2782 56.5738 53.4753 56.6175 53.6642L58.4131 61.439C58.4605 61.6421 58.4472 61.8546 58.3747 62.0501C58.3022 62.2457 58.1738 62.4155 58.0054 62.5386C57.837 62.6616 57.6362 62.7324 57.4279 62.7421C57.2196 62.7518 57.013 62.7 56.8339 62.5931L50.0472 58.476C49.8823 58.3757 49.693 58.3227 49.5 58.3227C49.307 58.3227 49.1177 58.3757 48.9528 58.476L42.1661 62.5931C41.9871 62.7 41.7805 62.7518 41.5722 62.7421C41.3639 62.7324 41.163 62.6616 40.9947 62.5386C40.8263 62.4155 40.6979 62.2457 40.6254 62.0501C40.5529 61.8546 40.5395 61.6421 40.587 61.439L42.3826 53.6642C42.4263 53.4753 42.4179 53.2782 42.3585 53.0937C42.2991 52.9092 42.1909 52.7442 42.0453 52.6163L36.0541 47.3901C35.8939 47.254 35.7777 47.0734 35.7202 46.8712C35.6627 46.669 35.6665 46.4543 35.731 46.2542C35.7956 46.0542 35.918 45.8778 36.0829 45.7473C36.2477 45.6169 36.4475 45.5383 36.6571 45.5214L44.5541 44.8388C44.7468 44.8216 44.9311 44.7522 45.0872 44.638C45.2433 44.5237 45.3653 44.3691 45.44 44.1906L48.5252 36.8381C48.6076 36.6481 48.7438 36.4862 48.917 36.3725C49.0902 36.2588 49.2928 36.1982 49.5 36.1982C49.7072 36.1982 49.9099 36.2588 50.0831 36.3725C50.2563 36.4862 50.3925 36.6481 50.4749 36.8381Z"
          fill="#FCDB00"
          stroke="#FCDB00"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_50_3471">
          <rect
            width="34"
            height="34"
            fill="white"
            transform="translate(32.5 33)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RewardCoinIcon;
