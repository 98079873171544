import React from "react";
import ReactDOM from "react-dom";
import reduxStore from "./store";
import { Provider } from "react-redux";
const parseUrl = require("parse-url");

import { registerFetchInterceptor } from "./api/helper";
import GenerateReceipt from "./Receipt";
import GenerateNewReceipt from "./NewReceipt";
import CustomerRedemption from "./CustomerRedemption";
import DealerVoucher from "./DealerVoucher";
import Feedback from "./Feedback";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { PersistGate } from "redux-persist/integration/react";
import NotFound from "./components/NotFound";

const urlData = parseUrl(window.location.href);
const theme = createTheme();
registerFetchInterceptor();

const renderUI = () => {
  try {
    if (urlData?.pathname?.includes("/cs/")) {
      return <CustomerRedemption />;
    } else if (urlData?.pathname?.includes("/dealer/")) {
      return <DealerVoucher />;
    } else if (urlData?.pathname?.includes("/fbk")) {
      return <Feedback />;
    } else if (!urlData?.query?.t) {
      const pathNames = urlData.pathname.split("/");
      // Ensure pathNames has at least 3 segments before accessing indexes 1 and 2
      if (pathNames.length >= 3) {
        return <GenerateNewReceipt />;
      } else {
        return <NotFound />;
      }
    } else if (urlData?.query?.t) {
      return <GenerateReceipt />;
    } else {
      return <NotFound />;
    }
  } catch (error) {
    console.error("Failed to decode path segments:", error);
    return <NotFound />; // Render a not found if decoding fails
  }
};

// Function to prevent right-click context menu
const handleContextMenu = (event) => {
  event.preventDefault();
};

// Add event listener to prevent right-click globally
document.addEventListener("contextmenu", handleContextMenu);

// Cleanup the event listener on dismount (if needed)
const cleanup = () => {
  document.removeEventListener("contextmenu", handleContextMenu);
};

// Call cleanup function when needed to remove the listener
window.addEventListener("unload", cleanup);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={reduxStore.store}>
        <PersistGate loading={null} persistor={reduxStore.persistor}>
          {renderUI()}
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
