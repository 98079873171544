import { openSuccessSnackbar } from "../../actions";

const moment = require("moment");

export const formatDate = (date) => {
  if (!!date) {
    return moment.utc(date).format("DD-MMM-YYYY");
  } else {
    return "";
  }
};

export const serializeSchema = (schemeState, _productList) => {
  return schemeState.map(
    ({
      scheme,
      activeSlab,
      rewardPoints,
      isredeemed,
      totalvolume,
      totalamount,
      redemptiondate,
      firstTransactionDate,
      transactionsList,
    }) => {
      const activeSlabIndex = scheme?.slabs.findIndex(
        (slab) => slab?.slab_id === activeSlab
      );
      const _activeSlab = scheme?.slabs[activeSlabIndex];

      const productsList = !scheme?.products?.trim()
        ? []
        : scheme?.products
            ?.trim()
            .split(",")
            .map((value) => Number(value.trim()));

      const products = productsList.map((productID) => {
        const prodObj = _productList.find((item) => {
          return item.local_prod_id === productID;
        });
        return prodObj ? prodObj.prod_name : [];
      });

      const regex = /^\s*,?\s*$/;
      const schemeProducts = regex.test(products?.join(", ").trim())
        ? ""
        : products?.join(", ").trim();

      return {
        offerid: scheme?.offerid,
        offername: scheme?.offername,
        slab_type: scheme?.slab_type,
        slabs: scheme?.slabs,
        effectivefrom: formatDate(scheme?.effectivefrom),
        effectivetill: formatDate(scheme?.effectivetill),
        redemption_end_date: scheme?.redemption_end_date,
        activeSlab: _activeSlab,
        activeSlabIndex: activeSlabIndex,
        schemeProducts: schemeProducts,
        eligibleAmount: rewardPoints || 0,
        totalCumulativeFueling: totalvolume || "",
        totalCumulativeAmount: totalamount || "",
        transactionsList,
        isredeemed,
        redemptionDate: redemptiondate,
        firstTransactionDate,
      };
    }
  );
};

export const isSchemeActive = (lastDate) => {
  const targetDate = new Date(lastDate);  // target date
  const now = new Date();  // current date in UTC
  // Convert current date in UTC to IST (UTC +5:30)
  const offset = 5.5 * 60 * 60 * 1000; // offset in milliseconds
  const currentISTDate = new Date(now.getTime() + offset);
  return targetDate >= currentISTDate;
};

export const columnDefs = [
  {
    field: "receiptId",
    headerName: "Receipt ID",
    minWidth: 110,
    flex: 1,
    resizable: true,
    suppressMovable: true,
    cellStyle: { textAlign: "center" },
  },
  {
    field: "roName",
    headerName: "RO Name",
    minWidth: 150,
    flex: 1,
    resizable: true,
    suppressMovable: true,
    cellStyle: { textAlign: "center" },
  },
  {
    field: "productName",
    headerName: "Product Name",
    minWidth: 100,
    suppressMovable: true,
    flex: 1,
    resizable: true,
    cellStyle: { textAlign: "center" },
  },
  {
    field: "transactionDate",
    headerName: "Transaction Date",
    minWidth: 125,
    suppressMovable: true,
    flex: 1,
    resizable: true,
    cellStyle: { textAlign: "center" },
  },
  {
    field: "volume",
    headerName: "Volume(L)",
    minWidth: 120,
    suppressMovable: true,
    flex: 1,
    resizable: true,
    cellStyle: { textAlign: "center" },
  },
  {
    field: "price",
    headerName: "Price(₹)",
    minWidth: 100,
    suppressMovable: true,
    flex: 1,
    resizable: true,
    cellStyle: { textAlign: "center" },
  },
  {
    field: "amount",
    headerName: "Amount(₹)",
    minWidth: 120,
    suppressMovable: true,
    flex: 1,
    resizable: true,
    cellStyle: { textAlign: "center" },
  },
];

export const TABLE_OPTIONS = {
  pagination: true,
  paginationPageSize: 10,
  cacheBlockSize: 10,

  localeText: {
    noRowsToShow: "No Data Found",
  },
  defaultColDef: {
    sortable: true,
  },
};

export const ERROR_MSGS = {
  required: "This is a required field.",
  numeric: "This is numeric field.",
  roidLength: "ROID must be 6-digit long.",
};
