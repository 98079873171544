import React from "react";
import Slide from "@mui/material/Slide";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  customDialogue: {
    // backgroundColor: "#fff !important",
    backdropFilter: "blur(2px)",
  },
  defaultIconSize: {
    height: "24px",
    width: "24px",
  },
  headerTitleParent: {
    alignItems: "center",
  },
  headerButtonsParent: {
    justifyContent: "flex-end",
    alignItems: "center",
  },

  cancelButton: {
    color: "#fff !important",
    float: "right",
    border: "2px solid #f75353 !important",
    margin: "10px !important",
    outline: "none !important",
    boxShadow: "none",
    padding: "7px 19px !important",
    borderRadius: "1.25rem !important",
    textTransform: "capitalize !important",
    backgroundColor: "#f75353 !important",
  },
  defaultButton: {
    color: "#fff !important",
    float: "right",
    border: "2px solid #087dc1 !important",
    margin: "10px !important",
    outline: "none !important",
    boxShadow: "none",
    padding: "7px 19px !important",
    borderRadius: "1.25rem !important",
    textTransform: "capitalize !important",
    backgroundColor: "#087dc1 !important",
  },
  disabledButton: {
    color: "#fff !important",
    float: "right",
    border: "2px solid #666666 !important",
    margin: "10px !important",
    outline: "none !important",
    boxShadow: "none",
    padding: "7px 19px !important",
    borderRadius: "1.25rem !important",
    textTransform: "capitalize !important",
    backgroundColor: "#666666 !important",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/*
    This Dialog Component renders a dialog
    @params 
    children {React.component} - renders the children in modal body
setIsEditPopupFullScreen {function} - returns true when dialog is set to fullscreen mode
hideFullScreenOption {boolean} - does not render fullscreen button when true
open {boolean} - render the dialog when true
maxWidth {xs/sm/md/lg/px -- string} - sets the max width of the dialog
fullWidth {boolean} - render the dialog such that it occupies full width alloted to it.
transition {ref} - set custom transition to the dialog
handleClose {function} - callback when close button is clicked
modalDetails {object} -- modalDetails.modalHeader {string}
modalDetails {object} -- modalDetails.actionButtonLabel {string}
title {string}
onUserActionSubmitHandler {function} - callback when user action button is clicked
onUserActionButtonCssObj {css object} - custom css for action button
isActionButtonDisabled {boolean} - disables the action button if true
// param added - doNotRenderActionsLayer {boolean} - disables the entire action layer if true
// param added - customDialogTitleCssObj {css object} - custom css for dialog title span element

*/
const DialogComponent = (props) => {
  const {
    children,
    setIsEditPopupFullScreen,
    hideFullScreenOption = false,
    open = false,
    maxWidth,
    fullWidth,
    transition,
    handleClose,
    modalDetails,
    title,
    isActionButtonDisabled = false,
    onUserActionSubmitHandler,
    onUserActionButtonCssObj,
    doNotRenderActionsLayer = false,
    customDialogTitleCssObj,
  } = props;

  const classes = useStyles();
  const [fullScreen, setFullScreen] = React.useState(false);

  const handleFullScreen = () => {
    setFullScreen?.(!fullScreen);
  };

  React.useEffect(() => {
    if (typeof setIsEditPopupFullScreen === "function") {
      setIsEditPopupFullScreen?.(fullScreen);
    }
  }, [fullScreen]);

  React.useEffect(() => {
    setFullScreen?.(false);
  }, [open]);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      TransitionComponent={transition || Transition}
      onClose={handleClose}
      className={classes.customDialogue}
    >
      <DialogTitle id="responsive-dialog-title" onClose={handleClose}>
        <Grid container className={classes.headerTitleParent}>
          {" "}
          <Grid item xs={true} className={customDialogTitleCssObj}>
            {modalDetails?.modalHeader || title}
          </Grid>
          <Grid item xs="auto">
            <div className={classes.headerButtonsParent}>
              {!hideFullScreenOption ? (
                fullScreen ? (
                  <FullscreenExitIcon
                    titleAccess="Exit Full Screen"
                    className={"modal-icon"}
                    onClick={handleFullScreen}
                  />
                ) : (
                  <FullscreenIcon
                    titleAccess="Enter Full Screen"
                    className={"modal-icon"}
                    onClick={handleFullScreen}
                  />
                )
              ) : null}

              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={"modal-icon"}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {!doNotRenderActionsLayer && (
        <DialogActions>
          <Button onClick={handleClose} className={classes.cancelButton}>
            Cancel
          </Button>
          {modalDetails?.actionButtonLabel ? (
            <Button
              onClick={() => {
                onUserActionSubmitHandler?.();
              }}
              className={
                isActionButtonDisabled
                  ? classes.disabledButton
                  : onUserActionButtonCssObj || classes.defaultButton
              }
              disabled={props && isActionButtonDisabled}
            >
              {modalDetails?.actionButtonLabel}
            </Button>
          ) : null}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DialogComponent;
