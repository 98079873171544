import React from "react";
import CustomTable from "../global/cutomTable/customTable";
import { Paper } from "@mui/material";
const moment = require("moment");

const VoucherHistoryTable = ({
  onGridReady,
  rowData,
  tableHeight,
  showRoName = false,
  showPagination = true,
  isCustomer = false,
}) => {
  const TABLE_OPTIONS = {
    pagination: showPagination,
    paginationPageSize: 10,
    cacheBlockSize: 10,
    localeText: {
      noRowsToShow: "No Data Found",
    },
    defaultColDef: {
      sortable: true,
    },
    // rowSelection: "single",
  };

  const columnDefs = [
    {
      field: "userName",
      headerName: "User Name",
      minWidth: 120,
      flex: 1,
      resizable: true,
      suppressMovable: true,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "mobileNo",
      headerName: "Mobile Number",
      minWidth: 150,
      flex: 1,
      resizable: true,
      suppressMovable: true,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "roName",
      headerName: "RO Name",
      minWidth: 150,
      suppressMovable: true,
      flex: 2,
      resizable: true,
      cellStyle: { textAlign: "center" },
      cellRendererFramework: (params) => {
        return params?.data?.roName ? params?.data?.roName : "-";
      },
    },
    {
      field: "voucherCode",
      headerName: "Voucher Code",
      minWidth: 130,
      flex: 1,
      resizable: true,
      suppressMovable: true,
      cellStyle: { textAlign: "center" },
    },
    // {
    //   field: "txnid",
    //   headerName: "Transaction Id",
    //   minWidth: 130,
    //   suppressMovable: true,
    //   flex: 1,
    //   resizable: true,
    //   cellStyle: { textAlign: "center" },
    // },
    {
      field: "points",
      headerName: "Redemption Points",
      minWidth: 120,
      suppressMovable: true,
      flex: 1,
      resizable: true,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "isRedeemed",
      headerName: "Status",
      minWidth: 150,
      suppressMovable: true,
      flex: 1,
      resizable: true,
      cellStyle: { textAlign: "center" },
      cellRendererFramework: (params) => {
        return !!params?.data?.isRedeemed ? "Redeemed" : "Not Redeemed";
      },
    },
    {
      field: "redeemedAt",
      headerName: "Redemption Date",
      minWidth: 150,
      suppressMovable: true,
      flex: 1,
      resizable: true,
      cellStyle: { textAlign: "center" },
      cellRendererFramework: (params) => {
        return params?.data?.redeemedAt
          ? moment(params?.data?.redeemedAt).format("Do MMM YYYY")
          : "-";
      },
    },
  ];

  return (
    <Paper elevation={3} style={{ padding: "10px" }}>
      <div>
        <CustomTable
          rowData={rowData.length ? rowData : []}
          gridOptions={{ ...TABLE_OPTIONS, columnDefs }}
          onGridReady={onGridReady}
          tableHeight={tableHeight}
        />
      </div>
    </Paper>
  );
};

export default VoucherHistoryTable;
