import { useEffect } from 'react';

function Feedback() {
  useEffect(() => {
    window.location.href = process.env.FEEDBACK_PATH || 'https://darpanpetrolpump.in/app/RAT804975';
  }, []);

  return null;
}

export default Feedback;