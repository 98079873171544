import React, { useState, useMemo, useRef } from "react";
import jsPDF from "jspdf";
import "./styles.css";
import { onboarding } from "./config";
import logo from './logo.jpeg'
import html2canvas from 'html2canvas';

const { host } = onboarding;
const parseUrl = require("parse-url");
const moment = require("moment");
function GenerateNewReceipt() {
  const [transaction, setTransaction] = useState({
    amount: "",
    nozzleNo: "",
    product: "",
    quantity: "",
    pumpNo: "",
    name: "",
    receiptID: "",
    txnTime: "",
    txnDate: "",
    vehicleNo: "",
    rsp: "",
    globalTxnID: "",
  });
  const [oldTransaction, setOldTransaction] = React.useState(false);
  const [imagePath, setImagePath] = React.useState({});
  const [freeText, setFreeText] = React.useState({});
  const contentRef = useRef(null);
  const [roDetails, setRoDetails] = useState({
    ROName: "",
    address: "",
    dealerMobile: "",
    dealerEmail: "",
    txnID: "",
  });
  const [transactionId, setTransactionId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Application: 'ONBOARDING',
    Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJwdWJsaWNAYnBjbC5jby5pbiIsInJvbGVzIjpbIlB1YmxpYyBVc2VyX29uYm9hcmRpbmciXSwiZXhwIjoxNzE1ODI2ODQ1LCJsb2dJZCI6MTE0NzQyLCJpYXQiOjE3MTU3ODM2NDV9.KmM5lgR1_QPfvPQ9EFXFHorF0N0yh5OxDiCpPhYr2QQ"
  };

  const radix64 = {
    _Rixits: "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz_-",
    toNumber: function (rixits) {
      var result = 0;
      rixits = rixits.split("");
      for (var e = 0; e < rixits.length; e++) {
        result = result * 64 + this._Rixits.indexOf(rixits[e]);
      }
      return result;
    },
  };

  React.useEffect(() => {

    if(!isLoading){
      const urlData = parseUrl(window.location.href);
      const pathNames = urlData.pathname.split("/");
      const roId = radix64.toNumber(pathNames[1]);
      const locTrnId = radix64.toNumber(pathNames[2]);
      setIsLoading(true);
      setTransactionId(locTrnId);
      fetchReceipt(roId, locTrnId.toString());
    }
  }, []);


  /**
   * Method to check if Transaction is 30 days old or not.
   * @param {*} dateString
   * @returns
   */
  const isTxn31DaysOld = (dateString) => {
    const date = new Date(dateString);
    const currentDate = new Date();
    // Calculate the difference in milliseconds between the current date and the given date
    const timeDifference = currentDate - date;
    // Convert the milliseconds difference to days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    // Check if the given date is 31 days old or not
    return daysDifference >= 31;
  };

  const fetchReceipt = (roId, transId) => {
    const transactionIdType = transId[0];
    const locTrnId = transId.substr(1);
    fetch(`${host}/ereceipt/${roId}/${locTrnId}`, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson)
        const {transactions = [], roDetail =[{}], imageData= [{}], freeText = [{}]   }  = responseJson
    console.log(transactions)
        if (transactions[0]?.txnDate && isTxn31DaysOld(transactions[0]?.txnDate)) {
          setOldTransaction(true);
          setIsLoading(false);
          return;
        }
        if (transactions.length && transactions[0]?.globalTxnID) {
          if (transactionIdType == "2") {
            // in case of feedback
            window.location = `https://wa.me/911800224344?text=Hi%2C+I+want+to+provide+feedback+for+the+recent+filling+that+I+did%2C+my+transaction+id+is+${transactions[0].globalTxnID}`;
            return;
          }
          setTransaction(transactions[0]);
        } else {
          setOldTransaction(true);
        }
        setFreeText(freeText[0]);
        setImagePath(imageData[0]);
        setRoDetails(roDetail[0]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setTransaction({});
        setFreeText({});
        setImagePath({});
        setRoDetails({});
        setOldTransaction(true);
      });
  };

  const fetchFreeText = () => {
    fetch(` ${host}/ereceipt/manage/free-text`, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setFreeText(responseJson[0]);
      })
      .catch((error) => {
        setFreeText({});
        console.log(error);
      });
  };

  const fetchPoster = () => {
    fetch(` ${host}/ereceipt/manage/poster`, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setImagePath(responseJson[0]);
      })
      .catch((error) => {
        setImagePath({});
        console.log(error);
      });
  };

  const fetchTransactionDetails = (roId, transId) => {
    const transactionIdType = transId[0];
    const locTrnId = transId.substr(1);
    fetch(`${host}/ereceipt/transaction/${roId}/${locTrnId}`, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[0] && responseJson[0]?.globalTxnID) {
          if (transactionIdType == "2") {
            // in case of feedback
            window.location = `https://wa.me/911800224344?text=Hi%2C+I+want+to+provide+feedback+for+the+recent+filling+that+I+did%2C+my+transaction+id+is+${responseJson[0].globalTxnID}`;
            return;
          }
          setTransaction(responseJson[0]);
        } else {
          setOldTransaction(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchRoDetails = (roId) => {
    fetch(`${host}/ros/${roId}`, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setRoDetails(responseJson[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const generatePDF = () => {
    const input = document.getElementById('ereceipt');
    const inputHeight = input.offsetHeight;
    const inputWidth = input.offsetWidth;
    const pdf = new jsPDF('p', 'px', [inputWidth, inputHeight]);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg', 0.8);
      pdf.addImage(imgData, 'PNG', 0, 0, inputWidth, inputHeight, 'FAST');
      pdf.save(transaction.globalTxnID + ".pdf");
    });
  };

  const formatMobileNumber = (mNo) =>{
    return mNo? mNo.replace(/\D/g, '').slice(-10): '';
   }

  const formatDistrictState = (dis, sts) =>{
    if(dis && sts){
      return `${dis}, ${sts}`
    }
    if(!dis && sts){
      return `${sts}`
    }
    return `${dis}`
  }

 

  const getMapsDirectionLink = (latitude, longitude) => {
    if (latitude && longitude) {
     return `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${latitude},${longitude}`;
    }
    return null
  };

  const parseTextWithLinks = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
  
    const parts = text.split(urlRegex);
    return parts.map((part, index) => {
      if (urlRegex.test(part)) {
        return (
          // Rel and target attributes for security reasons
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      } else {
        return part;
      }
    });
  };
  
 
   const { customerMNo, dealerMNo, districtState, mapDirectionLink } = useMemo(() => {
     return {
       customerMNo: formatMobileNumber(transaction?.mobileNo),
       dealerMNo: formatMobileNumber(roDetails?.contact1),
       districtState: formatDistrictState(roDetails?.district, roDetails?.state ),
       mapDirectionLink: getMapsDirectionLink(roDetails?.latitude, roDetails?.longitude)
     }
   }, [transaction, roDetails])

  return (
    isLoading
    ? <div className={"centreLoading"}>
      Loading
    </div>
    : <div className={"full-width"}>
      {!transactionId ? (
        <div style={{ margin: "auto" }}>Invalid URL</div>
      ) : (
        <div>
          {oldTransaction === false ? (
            <span>
              <button onClick={generatePDF} data-testid="download-button" style={{
                      marginBottom: '2rem',
                      marginTop: '1rem',
                    }}>
                Download
              </button>
              <div className="box">
                <table>
                  <div
                    className="container"
                    style={{
                      padding: '2rem'
                    }}
                    id="ereceipt"
                    ref={contentRef}
                  >
                    <div className="row">
                      <tr colSpan="2">
                        <div className="col image-align">
                          <img
                            id={'imageId'}
                            src={logo}
                            alt="BPCL Logo"
                            className="header-logo"
                          />
                        </div>
                      </tr>
                    </div>
                    {/* <div
                      className="row"
                      style={{ fontWeight: "600", fontSize: "small" }}
                    >
                      <tr colSpan="2">
                        <div className="col image-align">
                          {roDetails ? <b>{`${roDetails.roname}`}</b> : null}
                        </div>
                      </tr>
                    </div> */}
                    <div className="row">
                      <tr colSpan="2">
                        <div
                          className="col image-align"
                          style={{ fontWeight: "600", fontSize: "small" }}
                        >
                          {roDetails.roname}
                        </div>
                      </tr>
                    </div>
                    <div className="row">
                      <tr colSpan="2">
                        <div
                          className="col image-align"
                          style={{ fontWeight: "600", fontSize: "small" }}
                        >
                          {districtState}
                        </div>
                        <div
                          className="col image-align"
                        >
                        {Boolean(mapDirectionLink) && <a id="link" href={mapDirectionLink} target="_blank">
                         Get Direction
                        </a>}
                        </div>
                      </tr>
                    </div>
                    <div className="row">
                      <tr colSpan="2">
                        <div
                          className="col image-align"
                          style={{ fontWeight: "bolder", fontSize: "x-large" }}
                        >
                          Fuel E - Receipt
                        </div>
                      </tr>
                    </div>
                    <div className="row " style={{ border: "1px solid grey" }}>
                      <div className="col text-align1">Receipt&nbsp;ID</div>
                      {transaction &&
                        transaction.receiptID &&
                        transaction.receiptID.length ? (
                        <div className="col text-align">{`${transaction.receiptID}`}</div>
                      ) : null}
                    </div>{" "}
                    <div className="row " style={{ border: "1px solid grey" }}>
                    <div className="col text-align1">  Transaction&nbsp;Date & Time </div>
                      <div className="col text-align" >
                        {`${moment(new Date(transaction.txnDate)).format("Do MMM  YYYY")} ${transaction.txnTime}`}
                      </div>
                    </div>{" "}
                    <div className="row " style={{ border: "1px solid grey" }}>
                      <div className="col text-align1">Vehicle&nbsp;Number</div>
                      <div className="col text-align">{`${transaction.vehicleNo}`}</div>
                    </div>{" "}
                    <div className="row " style={{ border: "1px solid grey" }}>
                      <div className="col text-align1">Mobile&nbsp;Number</div>
                      <div className="col text-align">{`${customerMNo}`}</div>
                    </div>{" "}
                    <div className="row" style={{ border: "1px solid grey" }}>
                      <div className="col text-align1">MPD/Nozzle&nbsp;Number</div>
                      <div className="col text-align">{`${transaction.pumpNo}/${transaction.nozzleNo}`}</div>
                    </div>{" "}
                    <div className="row " style={{ border: "1px solid grey" }}>
                      <div className="col text-align1">Product&nbsp;Name</div>
                      <div className="col text-align">{`${transaction.product}`}</div>
                    </div>{" "}
                    <div className="row " style={{ border: "1px solid grey" }}>
                      <div className="col text-align1">Quantity&nbsp;(Ltr)</div>
                      <div className="col text-align">{`${transaction.quantity}`}</div>
                    </div>{" "}
                    <div className="row " style={{ border: "1px solid grey" }}>
                      <div className="col text-align1">
                        Price&nbsp;(Rs/ Ltr)
                      </div>
                      <div className="col text-align">{`${transaction.rsp}`}</div>
                    </div>{" "}
                    <div className="row " style={{ border: "1px solid grey" }}>
                      <div className="col text-align1">Amount&nbsp;(Rs)</div>
                      <div className="col text-align">{`${transaction.amount}`}</div>
                    </div>{" "}
                    <div className="row " style={{ border: '1px solid grey' }}>
                      <div className="col text-align1">Dealer Contact Number</div>
                        <div className="col text-align" >{`${dealerMNo}`}</div> 
                    </div>
                    {imagePath?.imageData && <div className="row">
                      <tr colSpan="2">
                        <div
                          className="col image-align"
                          style={{
                            fontWeight: "bolder",
                            fontSize: "x-large",
                            textAlign: "center",
                          }}
                        >  
                            <img
                              src={imagePath.imageData.img}
                              style={{ height: "100px", width: "400px" }}
                              alt="Red dot"
                            />
                          
                        </div>
                      </tr>
                    </div>}
                    { freeText?.freeText && <div className="row">
                      <tr colSpan="2">
                        <div
                          className="col image-align"
                          style={{
                            fontWeight: "bolder",
                            fontSize: "x-large",
                            textAlign: "center",
                            whiteSpace: 'pre-line'
                          }}
                        >
                          <b>{parseTextWithLinks(freeText.freeText)}</b>
                        </div>
                      </tr>
                    </div>}
                    <div className="row">
                      <tr colSpan="2">
                        <div className="col image-align">
                          You may also click on the link to share your
                          <br />
                          <a id="link" href={`https://wa.me/911800224344?text=Hi%2C+I+want+to+provide+feedback+for+the+recent+filling+that+I+did%2C+my+transaction+id+is+${transaction.globalTxnID}`}>
                          feedback/queries.
                          </a>
                        </div>
                      </tr>
                    </div>
                    <div className="row">
                      <tr colSpan="2">
                        <div className="col image-align">
                          Note - The Vehicle/Mobile number appearing above is as
                          per the details furnished by the Customer{" "}
                        </div>
                      </tr>
                    </div>
                    <div className="row">
                      <tr colSpan="2">
                        <div className="col image-align">
                          This is a computer generated e_receipt, Contact Petrol
                          Pump for any discrepancy or call us @ Toll Free
                          1800224344{" "}
                          Customer Service- 1800 22 4344
                        </div>
                      </tr>
                    </div>
                    <div className="row">
                      <tr colSpan="2">
                        <div className="col image-align">
                          Regd Office : Bharat Bhavan,&nbsp;4
                          &nbsp;and&nbsp;6,&nbsp;Currimbhoy Road,&nbsp;Ballard
                          Estate,&nbsp;P.&nbsp;B.&nbsp;No.&nbsp;688,&nbsp;Mumbai&nbsp;&nbsp;
                          - 400001.
                          <br />
                          CIN: L23220MH1952GOI008931
                        </div>
                      </tr>
                    </div>
                  </div>
                </table>
              </div>
            </span>
          ) : (
            <span>Link Expired</span>
          )}
        </div>
      )}
    </div>
  );
}

export default GenerateNewReceipt;
