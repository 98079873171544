import React from "react";

const MPDIcon = (props) => {
  return (
    <svg
      {...props}
      width={props?.width ?? "35"}
      height={props?.height ?? "30"}
      viewBox="0 0 35 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.75 28.75V3.75C4.75 3.08696 5.01339 2.45107 5.48223 1.98223C5.95107 1.51339 6.58696 1.25 7.25 1.25H19.75C20.413 1.25 21.0489 1.51339 21.5178 1.98223C21.9866 2.45107 22.25 3.08696 22.25 3.75V28.75"
        stroke={props?.color ?? "#0067AC"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 28.75H26"
        stroke={props?.color ?? "#0067AC"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.25 12.5H26C26.663 12.5 27.2989 12.7634 27.7678 13.2322C28.2366 13.7011 28.5 14.337 28.5 15V21.25C28.5 21.913 28.7634 22.5489 29.2322 23.0178C29.7011 23.4866 30.337 23.75 31 23.75C31.663 23.75 32.2989 23.4866 32.7678 23.0178C33.2366 22.5489 33.5 21.913 33.5 21.25V8.53594C33.5001 8.20743 33.4354 7.88213 33.3096 7.57863C33.1839 7.27514 32.9996 6.9994 32.7672 6.76719L29.75 3.75"
        stroke={props?.color ?? "#0067AC"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 12.5H9.75"
        stroke={props?.color ?? "#0067AC"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MPDIcon;
