import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function userLoginReducer(state = initialState.user, action) {
  switch (action.type) {
    case types.LOGIN_INITIATED:
      return {
        loginPending: true,
        error: null,
        loginError: false,
        loginSuccess: false,
        loginNotAllowed: false,
      };
    case types.LOGIN_INVALID_CREDS:
      return {
        loginPending: false,
        error: action.error,
        loginError: true,
        loginSuccess: false,
        loginNotAllowed: action.loginNotAllowed,
      };
    case types.LOGIN_FAILURE:
      return {
        loginPending: false,
        error: action.error,
        loginError: true,
        loginSuccess: false,
        loginNotAllowed: false,
      };
    case types.LOGIN_SUCCESS:
      return {
        loginPending: false,
        error: null,
        loginSuccess: true,
        response: action.user,
        loginError: false,
        loginNotAllowed: false,
      };
    case types.SEND_OTP_SUCCESS:
      return {
        loginPending: false,
        error: null,
        sendOtpSuccess: true,
        response: action.response.message,
        loginError: false,
        loginNotAllowed: false,
      };
    case types.UPDATE_SEND_OTP_SUCCESS:
      return {
        loginPending: false,
        error: null,
        sendOtpSuccess: false,
        response: null,
        loginError: false,
        loginNotAllowed: false,
      };
    case types.REQUEST_PASSWORD_INITIATED:
      return {
        requestPassword: true,
        requestPasswordError: null,
        requestPasswordErrorStatus: false,
        requestPasswordSuccess: false,
      };
    case types.REQUEST_PASSWORD_FAILURE:
      return {
        requestPassword: false,
        requestPasswordError: action.error,
        requestPasswordErrorStatus: true,
        requestPasswordSuccess: false,
      };
    case types.REQUEST_PASSWORD_SUCCESS:
      return {
        requestPassword: false,
        requestPasswordError: null,
        requestPasswordSuccess: true,
        requestPasswordResponse: action.user,
        requestPasswordErrorStatus: false,
      };
    case types.VALIDATE_TOKEN_INITIATED:
      return {
        validateToken: true,
        validateTokenError: null,
        validateTokenErrorStatus: false,
        validateTokenSuccess: false,
      };
    case types.VALIDATE_TOKEN_FAILURE:
      return {
        validateToken: false,
        validateTokenError: action.error,
        validateTokenErrorStatus: true,
        validateTokenSuccess: false,
      };
    case types.VALIDATE_TOKEN_SUCCESS:
      return {
        validateToken: false,
        validateTokenError: null,
        validateTokenSuccess: true,
        validateTokenResponse: action.user,
        validateTokenErrorStatus: false,
      };
    case types.RESET_PASSWORD_INITIATED:
      return {
        resetPassword: true,
        resetPasswordError: null,
        resetPasswordErrorStatus: false,
        resetPasswordSuccess: false,
      };
    case types.RESET_PASSWORD_FAILURE:
      return {
        resetPassword: false,
        resetPasswordError: action.error,
        resetPasswordErrorStatus: true,
        resetPasswordSuccess: false,
      };
    case types.RESET_PASSWORD_SUCCESS:
      return {
        resetPassword: false,
        resetPasswordError: null,
        resetPasswordSuccess: true,
        resetPasswordResponse: action.user,
        resetPasswordErrorStatus: false,
      };
    case types.CHANGE_PASSWORD_INITIATED:
      return {
        changePassword: true,
        changePasswordError: null,
        changePasswordErrorStatus: false,
        changePasswordSuccess: false,
      };
    case types.CHANGE_PASSWORD_FAILURE:
      return {
        changePassword: false,
        changePasswordError: action.error,
        changePasswordErrorStatus: true,
        changePasswordSuccess: false,
      };
    case types.CHANGE_PASSWORD_SUCCESS:
      return {
        changePassword: false,
        changePasswordError: null,
        changePasswordSuccess: true,
        changePasswordResponse: action.user,
        changePasswordErrorStatus: false,
      };
    case types.LOGOUT_INITIATED:
      return {
        userLogoutInitiated: true,
        userLogoutError: null,
        userLogoutErrorStatus: false,
        userLogoutSuccess: false,
      };
    case types.LOGOUT_FAILURE:
      return {
        userLogoutInitiated: false,
        userLogoutError: action.error,
        userLogoutErrorStatus: true,
        userLogoutSuccess: false,
      };

    default:
      return state;
  }
}
