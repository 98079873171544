import { TextField } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  searchBar: {
    marginBottom: 20,
    marginTop: 20,
  },
  tableHeading: {
    "&>th": {
      fontWeight: "bold",
    },
  },

  heading: {
    fontSize: "24px !important",
    fontWeight: "700 !important",
    color: "#087dc1",
  },

  //buttons
  redeemVoucher: {
    color: "#fff !important",
    float: "right",
    border: "2px solid #3bb469 !important", //can also use f75353
    // margin: "10px !important",
    outline: "none !important",
    boxShadow: "none",
    padding: "5px 20px !important",
    // borderRadius: "1.25rem !important",
    textTransform: "none !important",
    backgroundColor: "#3bb469 !important",
    fontSize: "16px !important",
  },
  validateVoucher: {
    color: "#fff !important",
    float: "right",
    border: "2px solid #087dc1 !important",
    // margin: "10px !important",
    outline: "none !important",
    boxShadow: "none",
    padding: "7px 19px !important",
    // borderRadius: "1.25rem !important",
    textTransform: "none !important",
    backgroundColor: "#087dc1 !important",
    // width: "100%",
    fontSize: "16px !important",
  },
  validateVoucherDisabled: {
    color: "#fff !important",
    float: "right",
    border: "2px solid #666666 !important",
    // margin: "10px !important",
    outline: "none !important",
    boxShadow: "none",
    padding: "7px 19px !important",
    // borderRadius: "1.25rem !important",
    textTransform: "none !important",
    backgroundColor: "#666666 !important",
    fontSize: "16px !important",
  },
  defaultButton: {
    color: "#fff !important",
    float: "right",
    border: "2px solid #087dc1 !important",
    // margin: "10px !important",
    outline: "none !important",
    boxShadow: "none",
    padding: "7px 19px !important",
    // borderRadius: "1.25rem !important",
    textTransform: "none !important",
    backgroundColor: "#087dc1 !important",
  },
  disabledButton: {
    color: "#fff !important",
    float: "right",
    border: "2px solid #666666 !important",
    // margin: "10px !important",
    outline: "none !important",
    boxShadow: "none",
    padding: "7px 19px !important",
    // borderRadius: "1.25rem !important",
    textTransform: "none !important",
    backgroundColor: "#666666 !important",
  },

  //dialog
  dialogTitle: {
    fontSize: "24px !important",
    fontWeight: "700 !important",
    color: "#087dc1",
  },
  detailHeading: {
    fontSize: "18px",
    fontWeight: "700",
    color: "#354463",
  },
  detailContent: {
    fontSize: "18px",
    fontWeight: "400",
    color: "#354463",
  },

  // voucher card
  voucherCardContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#ffffff",
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
    padding: "16px",
    marginTop: "16px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
  },
  voucherCardCode: {
    fontSize: "36px",
    fontWeight: "bold",
    color: "#1d1f21",
    paddingRight: "24px",
    borderRight: "2px solid #e0e0e0",
    height: "80px",
    display: "flex",
    alignItems: "center",
  },
  voucherCardDetails: {
    flexGrow: 1,
    padding: "0 24px",
  },
  voucherCardRedeemArea: {
    backgroundColor: "#4CAF50",
    color: "white",
    padding: "12px 24px",
    borderRadius: "4px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color 0.3s ease",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  },
  disabledVoucherCardRedeemArea: {
    backgroundColor: "#666666",
    color: "white",
    padding: "12px 24px",
    borderRadius: "4px",
    // cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color 0.3s ease",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  },
  voucherContent: {
    fontSize: "16px",
    fontWeight: "700",
    color: "#27ae60",
  },
  voucherContentDisabled: {
    fontSize: "16px",
    fontWeight: "700",
    color: "#666666",
  },
}));

export const CustomStyledTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#087dc1",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#087dc1",
  },
  "& .MuiInput-underline:before": {
    "&:hover": {
      borderBottomColor: "#087dc1",
    },
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottomColor: "#087dc1",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#087dc1",
    },
    "&:hover fieldset": {
      borderColor: "#087dc1",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#087dc1",
    },
  },
}));
