import * as types from "./actionTypes";
import authApi from "../api/login";
import { openSuccessSnackbar, openErrorSnackbar } from "./snackbar";

export function verifyOtp(userParams, isDealer = false) {
  return function (dispatch) {
    dispatch(userLoginPending());
    return authApi
      .verifyOtp(userParams, isDealer)
      .then((userResponse) => {
        if (!userResponse.status) {
          dispatch(
            userLoginError(
              "We're having trouble connecting to our servers at the moment. Please try again in a few minutes.",
              false
            )
          );
          dispatch(
            openErrorSnackbar({
              nessage:
                "We're having trouble connecting to our servers at the moment. Please try again in a few minutes.",
            })
          );
        } else if (userResponse.status === 200) {
          dispatch(userLoginSuccess(userResponse));
        } else {
          dispatch(
            userLoginError(userResponse.message, userResponse.status === 504)
          );
          dispatch(openErrorSnackbar(userResponse));
        }
      })
      .catch((error) => {
        dispatch(userLoginFailed(error));
      });
  };
}

export function sendOtp(userParams, isDealer = false) {
  return function (dispatch) {
    dispatch(userLoginPending());
    return authApi
      .sendOtp(userParams, isDealer)
      .then((userResponse) => {
        if (!userResponse.status) {
          dispatch(
            userLoginError(
              "No internet, Please check you internet connection !!",
              false
            )
          );
          dispatch(openErrorSnackbar(userResponse));
        } else if (userResponse.status === 200) {
          dispatch(sendOtpSuccess(userResponse));
          dispatch(openSuccessSnackbar(userResponse));
        } else {
          dispatch(
            userLoginError(userResponse.message, userResponse.status === 504)
          );
          dispatch(openErrorSnackbar(userResponse));
        }
      })
      .catch((error) => {
        dispatch(userLoginFailed(error));
      });
  };
}

export function logout(onSuccess) {
  return function (dispatch) {
    dispatch(userLogoutPending());
    return (
      authApi
        .customerLogout()
        .then((userResponse) => {
          if (userResponse.status && userResponse.status === 200) {
            dispatch(userLogoutSuccess(userResponse));
          } else dispatch(userLogoutError(userResponse));
        })
        .catch((error) => {
          dispatch(userLogoutError(error));
        })
        .finally(() => {
          localStorage.clear();
          onSuccess?.();
        })
    );
  };
}

export function userLoginPending() {
  return {
    type: types.LOGIN_INITIATED,
  };
}
export function userLoginSuccess(user) {
  return {
    type: types.LOGIN_SUCCESS,
    user,
  };
}
export function userLoginError(error, loginNotAllowed) {
  return {
    type: types.LOGIN_INVALID_CREDS,
    error,
    loginNotAllowed,
  };
}
export function userLoginFailed(error) {
  return {
    type: types.LOGIN_FAILURE,
    error,
  };
}
export function userLogoutPending() {
  return {
    type: types.LOGOUT_INITIATED,
  };
}
export function userLogoutSuccess(user) {
  return {
    type: types.LOGOUT_SUCCESS,
    user,
  };
}
export function userLogoutError(user) {
  return {
    // type: types.LOGOUT_SUCCESS,
    type: types.LOGIN_FAILURE,
    user,
  };
}
export function sendOtpSuccess(response) {
  return {
    type: types.SEND_OTP_SUCCESS,
    response,
  };
}
export function updateOtpSuccess(data) {
  return function (dispatch) {
    dispatch({
      type: types.UPDATE_SEND_OTP_SUCCESS,
      data,
    });
  };
}
