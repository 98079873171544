import React from "react";
import CustomTable from "../global/cutomTable/customTable";

const TABLE_OPTIONS = {
  pagination: true,
  paginationPageSize: 10,
  cacheBlockSize: 10,
  localeText: {
    noRowsToShow: "No Data Found",
  },
  defaultColDef: {
    sortable: true,
  },
  rowSelection: "single",
};

const RODetailsComponent = ({ onGridReady, onSelectionChanged, rowData }) => {
  const columnDefs = [
    {
      field: "roid",
      headerName: "RO ID",
      minWidth: 120,
      flex: 1,
      resizable: true,
      suppressMovable: true,
      cellStyle: { textAlign: "center" },
      cellRendererFramework: (params) => {
        const selectedRows = params?.api?.getSelectedRows();
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <input
              type="radio"
              name="roidRadio"
              value={params.data.roid}
              checked={
                !!selectedRows?.length
                  ? selectedRows[0].roid === params.data.roid
                    ? true
                    : false
                  : false
              }
            />
            <span>{params.data.roid}</span>
          </div>
        );
      },
    },
    {
      field: "roname",
      headerName: "RO Name",
      minWidth: 150,
      flex: 1,
      resizable: true,
      suppressMovable: true,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 150,
      flex: 1,
      resizable: true,
      suppressMovable: true,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "contact",
      headerName: "Phone Number",
      minWidth: 100,
      suppressMovable: true,
      flex: 1,
      resizable: true,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "city",
      headerName: "City",
      minWidth: 100,
      suppressMovable: true,
      flex: 1,
      resizable: true,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "state",
      headerName: "State",
      minWidth: 125,
      suppressMovable: true,
      flex: 1,
      resizable: true,
      cellStyle: { textAlign: "center" },
    },
  ];

  const _rowData = [
    { roid: 100100, roname: "Sample Ro", city: "Mumbai", state: "Maharashtra" },
    { roid: 100100, roname: "Sample Ro", city: "Mumbai", state: "Maharashtra" },
    { roid: 100100, roname: "Sample Ro", city: "Mumbai", state: "Maharashtra" },
  ];

  return (
    <div>
      <CustomTable
        rowData={rowData.length ? rowData : []}
        gridOptions={{ ...TABLE_OPTIONS, columnDefs }}
        onGridReady={onGridReady}
        onSelectionChanged={onSelectionChanged}
      />
    </div>
  );
};

export default RODetailsComponent;
