import { Button, Grid, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import RewardCoinIcon from "../../assets/svg/RewardCoinIcon";
import { isSchemeActive } from "../../CustomerRedemption/dashboard_revamp/dashboard.utils";
import { formatDate } from "../../CustomerRedemption/scheme/scheme.utils";
import CircularProgressWrapper from "../global/CircularProgressWrapper";

const RewardClaimComponent = (props) => {
  const schemeActiveStatus = useMemo(() => {
    return isSchemeActive(props?.scheme?.redemption_end_date);
  }, [props?.scheme]);

  return (
    <>
      <div className="redemption-container">
        <Grid
          container
          direction={"column"}
          sx={{ alignItems: "center" }}
          spacing={2}
        >
          <Grid item className="reward-symbol-container">
            <div className="reward-coin-icon-container">
              <RewardCoinIcon />
            </div>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 600,
                color: "#65A200",
                paddingTop: "16px",
                textAlign: "center",
              }}
            >
              Rewards Earned!
            </Typography>
          </Grid>
          <Grid item className="reward-points-container">
            <div className="reward-points-data">
              <Typography
                variant="inline"
                sx={{
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#666666",
                }}
              >
                You earned
              </Typography>
              &nbsp;
              <RewardCoinIcon height={24} width={24} />
              &nbsp;
              <Typography
                variant="inline"
                sx={{
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#666666",
                }}
              >
                {props?.scheme?.eligibleAmount} points
              </Typography>
            </div>
            {!props?.scheme?.isredeemed ? (
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#666666",
                  textAlign: "center",
                  paddingTop: "16px",
                }}
              >
                Redeem these points to save up on your next purchases
              </Typography>
            ) : null}
          </Grid>
          <Grid
            item
            className="reward-claim-container"
            sx={{ textAlign: "center" }}
          >
            <CircularProgressWrapper minHeight={"calc(80px)"} isLoading={!!props?.isRedeemLoading}>
              <Button
                variant="contained"
                disabled={props?.scheme?.isredeemed || !schemeActiveStatus}
                onClick={() => {
                  if (props?.scheme?.isredeemed || !schemeActiveStatus) {
                    return;
                  } else {
                    props?.handleRedeem(props?.scheme?.offerid);
                  }
                }}
                sx={{
                  padding: "18px 36px 18px 36px",
                  textTransform: "capitalize",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: 500,
                    color: "#FFFFFF",
                  }}
                >
                  Create Voucher
                </Typography>
              </Button>
            </CircularProgressWrapper>
            {/* {props?.scheme?.isredeemed ? (
              <>
                {!!props?.scheme?.redemptionDate ? (
                  <>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "#666666",
                        textAlign: "center",
                        paddingTop: "10px",
                      }}
                    >
                      Your Reward Redemption Date:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: 500,
                        color: "#1A1A1A",
                        textAlign: "center",
                      }}
                    >
                      {formatDate(props?.scheme?.redemptionDate)}
                    </Typography>
                  </>
                ) : null}
              </>
            ) : ( */}
            <>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 400,
                  color: "#666666",
                  textAlign: "center",
                  paddingTop: "10px",
                }}
              >
                Last date to redeem:
              </Typography>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 500,
                  color: "#1A1A1A",
                  textAlign: "center",
                }}
              >
                {formatDate(props?.scheme?.redemption_end_date)}
              </Typography>
            </>
            {/* )} */}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default RewardClaimComponent;
