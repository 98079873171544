import { Grid, Typography } from "@mui/material";
import { React } from "react";
import DropIcon from "../../assets/svg/DropIcon";
import MPDIcon from "../../assets/svg/MPDIcon";
import { formattedNumber, toPascalCase } from "../../utils";
import { formatDate } from "../../CustomerRedemption/scheme/scheme.utils";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

const SchemeDetailsComponent = (props) => {
  return (
    <Grid container sx={{ borderBottom: "2px solid #E6E6E6" }}>
      <Grid item xs={12}>
        <div className="scheme-name-date-time">
          <Typography sx={{ fontSize: 32, fontWeight: 600, color: "#1A1A1A" }}>
            {toPascalCase(props?.scheme?.offername)}
          </Typography>
          <Typography sx={{ fontSize: 16, fontWeight: 400, color: "#666666" }}>
            From {formatDate(props?.scheme?.effectivefrom)} to{" "}
            {formatDate(props?.scheme?.effectivetill)}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="fuel-product-details">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              {props?.scheme?.slab_type === "volume" ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <MPDIcon color="#0067AC" height="40" width="40" />

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      marginLeft: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "#666666",
                      }}
                    >
                      Total Fuel
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 24,
                        fontWeight: 600,
                        color: "#1A1A1A",
                      }}
                    >
                      {formattedNumber(props?.scheme?.totalCumulativeFueling)} L
                    </Typography>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CurrencyRupeeIcon
                    style={{ color: "#0067AC", fontSize: 40 }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      marginLeft: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "#666666",
                      }}
                    >
                      Total Amount
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 24,
                        fontWeight: 600,
                        color: "#1A1A1A",
                      }}
                    >
                      {/* &#x20B9;{formattedNumber(props?.scheme?.totalCumulativeAmount)} */}
                      {formattedNumber(props?.scheme?.totalCumulativeAmount)}
                    </Typography>
                  </div>
                </div>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              // className="custom-mobile-top-padding"
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <DropIcon color="#0067AC" height="40" width="40" />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    marginLeft: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: "#666666",
                    }}
                  >
                    Product
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 24,
                      fontWeight: 600,
                      color: "#1A1A1A",
                    }}
                  >
                    {props?.scheme?.schemeProducts}
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default SchemeDetailsComponent;
