import * as types from "./actionTypes";
export function closeSnackbar() {
  return {
    type: types.CLOSE_SNACKBAR,
  };
}

export function openSuccessSnackbar(snackbarData) {
  return {
    type: types.OPEN_SUCCESS_SNACKBAR,
    snackbarData,
  };
}

export function openErrorSnackbar(snackbarData) {
  return {
    type: types.OPEN_ERROR_SNACKBAR,
    snackbarData,
  };
}
