export const VoucherRedemptionData = [
  {
    userName: "XYZ",
    mobNumber: "1111122222",
    voucherCode: "ABCDWXYZ",
    redeemAmount: "75",
    redeemedAt: "21st Oct 2024",
    txnId: "1122TXN235",
    roName: "BPCL RHQ - Maker Tower",
  },
];

export const FORM_FIELD_VALIDATION_MAP = {
  voucherCode: {
    maxLength: 8,
  },
  txnId: {
    maxLength: 10,
  },
};

export const INIT_ERROR_OBJ = {
  voucherCode: null,
  otp: null,
  txnId: null,
};
