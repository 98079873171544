import { bpclProfiles } from "./../config";
import auth from "../modules/auth";
const bpclProfileHost = bpclProfiles.host;

class authApi {
  static getSchemeDetails({ mobile_no }) {
    const queryString = new URLSearchParams({
      mobile_no,
    }).toString();

    return fetchWithInterceptor(`${bpclProfileHost}/schemeDetails?${queryString}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }

  static redeemReward(userParams) {
    return fetchWithInterceptor(`${bpclProfileHost}/redeem_reward`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(userParams),
    })
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }

  static fetchVoucherHistory(userParams) {
    const queryString = new URLSearchParams(userParams).toString();
    return fetchWithInterceptor(`${bpclProfileHost}/voucherHistory?${queryString}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }

  static getVoucherDetails(userParams) {
    const queryString = new URLSearchParams({
      voucherCode: userParams?.voucherCode,
    }).toString();
    return fetchWithInterceptor(`${bpclProfileHost}/voucher-details?${queryString}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }
  
  static redeemVoucher(userParams) {
    const formdata = new FormData();
    formdata.append("voucherCode", userParams.voucherCode);
    formdata.append("otp", userParams.otp);
    formdata.append("txnid", userParams.txnid);
    if (userParams.file) {
      formdata.append("file", userParams.file, userParams.fileName);
    }
    return fetchWithInterceptor(`${bpclProfileHost}/verify-voucher-otp`, {
      headers: {
        Accept: "application/json",
        //"Content-Type": "application/json",
      },
      method: "POST",
      body: formdata,
    })
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }

  static getProductList() {
    return fetchWithInterceptor(`${bpclProfileHost}/product_list`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }

  static getFilteredROList({ offerId, state, city, roid, roName }) {
    let apiPath = `${bpclProfileHost}/get-filtered-ros/${offerId}`;

    if (state || city || roid || roName) {
      apiPath += "?";
      if (state) apiPath += `state=${state}&`;
      if (city) apiPath += `city=${city}&`;
      if (roid) apiPath += `roid=${roid}&`;
      if (roName) apiPath += `roname=${roName}&`;
    }

    return fetchWithInterceptor(apiPath, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }
}

export default authApi;
