import * as types from "./actionTypes";
import authApi from "../api/redemption";
import { openErrorSnackbar, openSuccessSnackbar } from "./snackbar";

export const getSchemeDetails = (userParams, onSuccess) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.INIT_REDEMPTION_LOADING });
      const response = await authApi.getSchemeDetails(userParams);
      const productListResponse = await authApi.getProductList();
      if (
        response.data &&
        response.status === 200 &&
        productListResponse.data &&
        productListResponse.status === 200
      ) {
        dispatch({
          type: types.STORE_REDEMPTION_SUCCESS,
          payload: {
            schemeData: response.data,
            productList: productListResponse?.data?.result,
          },
        });
        onSuccess?.(response.data);
      } else {
        dispatch({ type: types.STORE_REDEMPTION_ERROR });
      }
    } catch (error) {
      console.log("!!error", error);
      dispatch({ type: types.STORE_REDEMPTION_ERROR, payload: error });
    }
  };
};

export const getVoucherDetails = (userParams, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.INIT_VOUCHER_LOADING });
      const response = await authApi.getVoucherDetails(userParams);
      if (
        response.voucher &&
        response.status === 200
      ) {
        dispatch({
          type: types.STORE_VOUCHER_SUCCESS,
          payload: {
            voucher: response.voucher,
          },
        });
        onSuccess?.(response.voucher);
        dispatch(openSuccessSnackbar(response));
      } else {
        onError();
        dispatch({ type: types.STORE_VOUCHER_ERROR });
        dispatch(openErrorSnackbar(response));
      }
    } catch (error) {
      onError();
      console.log("!!error", error);
      dispatch(openErrorSnackbar(error));
      dispatch({ type: types.STORE_VOUCHER_ERROR, payload: error });
    }
  };
};

export const getVoucherHistory = (userParams, onSuccess) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.INIT_VOUCHER_HISTORY_LOADING });
      const response = await authApi.fetchVoucherHistory(userParams);
      if (
        response.data &&
        response.status === 200
      ) {
        dispatch({
          type: types.STORE_VOUCHER_HISTORY_SUCCESS,
          payload: {
            voucherHistory: response.data,
          },
        });
        onSuccess?.(response.voucher);
      } else {
        dispatch({ type: types.STORE_VOUCHER_HISTORY_ERROR });
        dispatch(openErrorSnackbar(response));
      }
    } catch (error) {
      console.log("!!error", error);
      dispatch(openErrorSnackbar(error));
      dispatch({ type: types.STORE_VOUCHER_HISTORY_ERROR, payload: error });
    }
  };
};

export const verifyVoucherAndRedeem = (userParams, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.INIT_REDEMPTION_LOADING });
      const response = await authApi.redeemVoucher(userParams);
      if (
        response.status === 200 ||
        response.status === 202
      ) {
        onSuccess();
        dispatch(openSuccessSnackbar(response));
        dispatch({ type: types.RESET_VOUCHER });
      } else {
        onError();
        dispatch(openErrorSnackbar(response));
        dispatch({ type: types.STORE_REDEMPTION_ERROR });
      }
    } catch (error) {
      onError();
      dispatch({ type: types.STORE_REDEMPTION_ERROR });
      dispatch(openErrorSnackbar(error));
      console.log("!!error", error);
    }
  };
};


export const redeemRewards = (userParams, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.INIT_REDEEM_VOUCHER_LOADING });
      const response = await authApi.redeemReward(userParams);
      if (
        response.status === 200 ||
        response.status === 202
      ) {
        onSuccess();
        dispatch(openSuccessSnackbar(response));
        dispatch({ type: types.INIT_REDEEM_VOUCHER_SUCCESS });
      } else {
        onError();
        dispatch(openErrorSnackbar(response));
        dispatch({ type: types.INIT_REDEEM_VOUCHER__ERROR });
      }
    } catch (error) {
      onError();
      dispatch({ type: types.INIT_REDEEM_VOUCHER__ERROR });
      dispatch(openErrorSnackbar(error));
      console.log("!!error", error);
    }
  };
};

