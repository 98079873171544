import React, { useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import SignIn from "./dealer_signin";
import Dashboard from "./dealer_dashboard";
import SnackbarComponent from "../CustomerRedemption/SnackbarComponent";
import { useDevToolsLogout } from "../utils/detectDevTools";
import IdleTimer from "../utils/idleTimer";
import { useSelector } from "react-redux";
import { getValue } from "../utils/encryption";

const DealerVoucher = () => {

  const idleTimeChecker = useRef(null);

  const logoutHandler = () => {
    const userId = getValue("_userId");
    if (userId){
      localStorage.clear();
      window.location =  "/dealer/si/";
    }
  }

  useDevToolsLogout(logoutHandler);

  useEffect(() => {
    async function initializeIdleTimer() {
      if (idleTimeChecker.current) {
        idleTimeChecker.current.cleanup();
      }
      const userId = getValue("_userId");

      if (userId) {
        const idleTimer = new IdleTimer({
          timeout: 30 * 60, // 30 minutes
          sessionKey: "_activityTime", // Use the correct session key
          onExpired: async () => {
            localStorage.clear();
            window.location =  "/dealer/si/";
          },
        });

        await idleTimer.init();
        idleTimeChecker.current = idleTimer;
      }
    }

    initializeIdleTimer();

    return () => {
      if (idleTimeChecker.current) {
        idleTimeChecker.current.cleanup();
      }
    };
  }, []);


  return (
    <>
      <Router basename="/dealer">
        <Routes>
          <Route path="/" exact element={<SignIn />} />
          <Route path="/si" element={<SignIn />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
        <SnackbarComponent />
      </Router>
    </>
  );
};

export default DealerVoucher;
