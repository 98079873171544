import React, { useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SignIn from "./signin";
import SignUp from "./signup/CustomerRegistration";
// import Dashboard from "./dashboard";
import Dashboard from "./dashboard_revamp";
import Scheme from "./scheme";
import SnackbarComponent from "./SnackbarComponent";
import CustomerSchemeRegistration from "./signup/CustomerSchemeRegistration";
import { useDevToolsLogout } from "../utils/detectDevTools";
import { useSelector } from "react-redux";
import IdleTimer from "../utils/idleTimer";
import { getValue } from "../utils/encryption";

const CustomerRedemption = () => {
  const idleTimeChecker = useRef(null);

  const logoutHandler = () => {
    const userId = getValue("_userId");
    if (userId){
      const unique_id = getValue("_userUniqueId");
      localStorage.clear();
      window.location = unique_id ? "/cs/si/" + unique_id : "/cs/si/";
    }
  }

  useDevToolsLogout(logoutHandler);

  useEffect(() => {
    async function initializeIdleTimer() {
      if (idleTimeChecker.current) {
        idleTimeChecker.current.cleanup();
      }

      const userId = getValue("_userId");

      if (userId) {
        const idleTimer = new IdleTimer({
          timeout: 30 * 60, // 30 minutes
          sessionKey: "_activityTime", // Use the correct session key
          onExpired: async () => {
            const unique_id = getValue("_userUniqueId");
            localStorage.clear();
            window.location = unique_id ? "/cs/si/" + unique_id : "/cs/si/";
          },
        });

        await idleTimer.init();
        idleTimeChecker.current = idleTimer;
      }
    }

    initializeIdleTimer();

    return () => {
      if (idleTimeChecker.current) {
        idleTimeChecker.current.cleanup();
      }
    };
  }, []);

  
  return (
    <>
      <Router basename="/cs">
        <Routes>
          <Route path="/" exact element={<SignUp />} />
          <Route path="/si/:uniqueid" element={<SignIn />} />
          <Route path="/si" element={<SignIn />} />
          <Route path="/customerRegistration" element={<SignUp />} />
          {/* <Route path="/sr" element={<SignUp />} />
          <Route path="/sr/:uniqueid" element={<SignUp />} /> */}
          <Route path="/csr" element={<CustomerSchemeRegistration />} />
          <Route
            path="/csr/:uniqueid"
            element={<CustomerSchemeRegistration />}
          />
          {/* <Route path="/profile" element={<SignUp />} /> */}
          <Route path="/dashboard" element={<Dashboard />} />
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          <Route path="/scheme/:schemeId" element={<Scheme />} />
        </Routes>
        <SnackbarComponent />
      </Router>
    </>
  );
};

export default CustomerRedemption;
