import React from "react";

const DropIcon = (props) => {
  return (
    <svg
      {...props}
      width={props?.width ?? "40"}
      height={props?.height ?? "40"}
      // viewBox={
      //   props?.width && props?.height
      //     ? toString("0 0" + " " + props?.width + " " + props?.height)
      //     : "0 0 40 40"
      // }
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_50_3298)">
        <path
          d="M32.5 22.5C32.5 11.25 20 2.5 20 2.5C20 2.5 7.5 11.25 7.5 22.5C7.5 25.8152 8.81696 28.9946 11.1612 31.3388C13.5054 33.683 16.6848 35 20 35C23.3152 35 26.4946 33.683 28.8388 31.3388C31.183 28.9946 32.5 25.8152 32.5 22.5Z"
          stroke={props?.color ?? "#0067AC"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.25 30C24.375 29.4734 26.9703 26.875 27.5 23.75"
          stroke={props?.color ?? "#0067AC"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_50_3298">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DropIcon;
