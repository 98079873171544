import user from "./login";
import signup from "./signup";
import redemption from "./redemption";
import snackbar from "./snackbar";

export default {
  user,
  signup,
  redemption,
  snackbar,
};
