import { Box, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProgressWrapper from "../../components/global/CircularProgressWrapper";
import RewardClaimComponent from "../../components/scheme/RewardClaimComponent";
import SchemeDetailsComponent from "../../components/scheme/SchemeDetailsComponent";
import SlabEligibilityComponent from "../../components/scheme/SlabEligibilityComponent";
import TransactionDetailsComponent from "../../components/scheme/TransactionDetailsComponent";
import MenuBar from "../menubar";
import RedemptionModal from "./RedemptionModal";
import { useMainHook } from "./scheme.hook";
import "./schemes.css";
import authApi from "../../api/redemption";
import { toPascalCase } from "../../utils";
import VoucherHistoryTable from "../../components/dealer_dashboard/voucherHistoryTable";
import { redeemRewards } from "../../actions";

const useStyles = makeStyles((theme) => ({}));

const Scheme = () => {
  const isMobile = useMediaQuery("(max-width:899px)");
  const [indexValue, setIndexValue] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const { selectedScheme, getCustomerSchemeDetails, isLoading, isRedeemLoading, onSuccessCb } = useMainHook();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const { voucherHistory } = useSelector((state) => state?.redemption);

  //Commented for Ghar Scheme
  // const handleRedeem = (offerid) => {
  //   setOpen(true);
  // };
  const handleRedeem = (offerid) => {
    const obj = {
      offerid: offerid,
      redeemPoints: Number(selectedScheme?.eligibleAmount || 0),
    };
    if (obj.offerid) {
      const successCb = () => { onSuccessCb() };
      const errorCb = () => {};
      dispatch(redeemRewards(obj, successCb, errorCb));
    }
  };

  function TabPanel(props) {
    const { children, value, index, classes, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`schemes-tabpanel-${index}`}
        aria-labelledby={`schemes-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  const handleChange = (event, newValue) => {
    setIndexValue(newValue);
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <MenuBar sideBar title="Scheme" />
      <div className="scheme-base-parent-div">
        <Paper elevation={4} className="base-paper">
          <CircularProgressWrapper isLoading={!!isLoading}>
            <Grid container className="upper-container">
              <Grid item xs={12} sm={12} md={8}>
                <SchemeDetailsComponent scheme={selectedScheme} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                sx={
                  isMobile
                    ? {
                        borderBottom: "2px solid #E6E6E6",
                      }
                    : {
                        borderBottom: "2px solid #E6E6E6",
                        borderLeft: "2px solid #E6E6E6",
                      }
                }
              >
                <SlabEligibilityComponent
                  value={indexValue}
                  handleChange={handleChange}
                  scheme={selectedScheme}
                />
              </Grid>
            </Grid>
            <Grid
              container
              className="lower-container"
              direction={isMobile ? "column-reverse" : "row"}
            >
              <Grid item xs={12} sm={12} md={8}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="Dashboard"
                  >
                    <Tab
                      label={toPascalCase("Voucher's")}
                      className="tab-label"
                      id={0}
                      aria-controls="dashboard-tabpanel-0"
                    />
                    <Tab
                      label={toPascalCase("Transaction's")}
                      className="tab-label"
                      id={1}
                      aria-controls="dashboard-tabpanel-1"
                    />
                  </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0}>
                  <VoucherHistoryTable rowData={voucherHistory} isCustomer={true} />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <TransactionDetailsComponent scheme={selectedScheme} />
                </TabPanel>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                sx={
                  isMobile
                    ? { borderBottom: "2px solid #E6E6E6" }
                    : { borderLeft: "2px solid #E6E6E6" }
                }
              >
                <RewardClaimComponent
                  scheme={selectedScheme}
                  handleRedeem={handleRedeem}
                  isRedeemLoading={isRedeemLoading}
                />
              </Grid>
            </Grid>
          </CircularProgressWrapper>
        </Paper>
      </div>
      <RedemptionModal
        open={open}
        setOpen={setOpen}
        isLoading={isLoading}
        getCustomerSchemeDetails={getCustomerSchemeDetails}
        scheme={selectedScheme}
      />
    </>
  );
};

export default Scheme;
