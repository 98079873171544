import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getConsentDetails,
  updateProfile,
  sendSignUpOtp,
} from "../../../actions";
import authApi from "../../../api/signup";
import {
  allowedExtensions,
  allowOnlyNumbers,
  allowPincodeOrOtp,
  consecutiveNewLineRegex,
  allowOnlyAlphabetsAndSpaces,
} from "../../../utils";
import MenuBar from "../../menubar";
import CustomerConsent from "./consent";
import {
  FORM_TYPE,
  fullFormValidation,
  POI_TYPE,
  sloganFormValidation,
} from "./CSR.utils";
import FullRegistrationForm from "./FullRegistrationForm";
import SloganForm from "./SloganForm";
import VerifyMobileNoModal from "./verifyMobilenoModal";
import { getValue } from "../../../utils/encryption";

const useStyles = makeStyles({
  customerRegistrationCard: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "120px 15px 20px",
  },
  textField: {
    margin: 0,
    padding: 0,
    width: "100%",
  },

  AttachIcon: {
    margin: 0,
    padding: 0,
    width: "100%",
  },
  registrationFormMain: {
    marginTop: 32,
  },
  consent: {
    marginTop: 16,
    display: "flex",
    alignItems: "center",
  },
  registerButton: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  vehicleSection: {
    display: "flex",
    alignItems: "center",
  },
  PoISection: {
    display: "flex",
    alignItems: "center",
  },
  consentLink: {
    cursor: "pointer",
    paddingLeft: 5,
    color: "red",
  },
  consentError: {
    marginLeft: 20,
    marginTop: -10,
  },
  thankyou: {
    height: 200,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 20,
    color: "rgb(46, 125, 50)",
    fontWeight: "bold",
  },
  CheckIcon: {
    fontSize: 60,
    marginBottom: 10,
  },
});

const INITIAL_ERROR = {
  customerName: "",
  mobile: "",
  pincode: "",
  slogan: "",
  consentAccepted: "",
  PoIDetails: {
    PoIerrorMsg: "",
    value: "",
    type: "",
    file: "",
    fileName: "",
  },
};

const INITIAL_STATE = {
  customerName: "",
  mobile: "",
  address: "",
  slogan: "",
  PoIDetails: {
    isSelected: false,
    value: "",
    type: "",
    file: "",
    fileName: "",
  },
  pincode: "",
  city: "",
  state: "",
  consentAccepted: false,
};

const convertToUppercase = (str) => {
  if (!str) {
    return str;
  }
  return str.replace(/[a-z]/g, (char) => {
    return char.toUpperCase();
  });
};

const CustomerSchemeRegistration = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { uniqueid } = useParams();
  const fullState = useSelector((state) => state.signup);
  const dispatch = useDispatch();
  const schemeRegistrationFlag = location.pathname.split("/").includes("csr");
  const profilePage = location.pathname === "/profile";
  const [customer, setCustomer] = useState(INITIAL_STATE);
  const [error, setError] = useState(INITIAL_ERROR);
  const [open, setOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [consentData, setConsentData] = useState("");
  const userData = getValue("_userData") && JSON.parse(getValue("_userData"));
  const fileInputRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (uniqueid && schemeRegistrationFlag) {
      authApi
        .getSmsDetails(uniqueid)
        .then((smsDetails) => {
          setCustomer((prevFormData) => ({
            ...prevFormData,
            mobile: smsDetails?.data?.mobile_number,
            offerid: smsDetails?.data?.offer_id,
          }));
        })
        .catch((error) => {
          console.log("error=>", error);
        });
    }
  }, [uniqueid]);

  useEffect(() => {
    if (customer.offerid) {
      dispatch(getConsentDetails(customer.offerid));
      localStorage.setItem("_offerid", customer.offerid);
    }
  }, [customer.offerid]);

  useEffect(() => {
    setUserName(userData?.customer_name);
    if (profilePage && !!userData) {
      setCustomer((prevFormData) => ({
        ...prevFormData,
        mobile: userData?.mobile_no || "",
        customerName: userData?.customer_name || "",
        city: userData?.city || "",
        slogan: userData?.slogan || "",
        PoIDetails: userData?.PoIDetails || INITIAL_STATE.PoIDetails,
        state: userData?.state || "",
        pincode: userData?.pin_code || "",
        address: userData?.add_line1 || "",
      }));
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const consentAgree = () => {
    setOpen(false);
    setCustomer((prevFormData) => ({
      ...prevFormData,
      consentAccepted: true,
    }));
    setError((prevErrorData) => ({
      ...prevErrorData,
      consentAccepted: "",
    }));
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const registerCustomer = () => {
    let errorObj = Object.assign({}, INITIAL_ERROR);
    let isError = false;
    const {
      customerName,
      mobile,
      slogan,
      PoIDetails,
      offerid,
      consentAccepted,
      address,
      pincode,
      city,
      state,
    } = customer;

    if (consentData?.form_type === FORM_TYPE.slogan) {
      isError = sloganFormValidation(customer, errorObj, profilePage);
    } else if (consentData?.form_type === FORM_TYPE.full) {
      isError = fullFormValidation(customer, errorObj, profilePage);
    }

    if (!!isError) {
      setError(errorObj);
      return;
    } else {
      const payload = {
        customer_name: customerName,
        // mobile_no: mobile,
        unique_id: uniqueid || "",
        email_id: "",
        slogan: slogan,
        address: address,
        offer_id: offerid,
        city: city,
        state: state,
        pin_code: pincode,
        customer_consent: consentAccepted ? true : false,
        poi: PoIDetails.value,
        poi_type: PoIDetails.type,
        file: PoIDetails.file,
        fileName: PoIDetails.fileName,
      };
      if (profilePage) {
        dispatch(updateProfile(payload));
      } else {
        handleSendOtp();
      }
    }
  };

  const handleSendOtp = () => {
    const payload = {
      userId: uniqueid,
      offerid: customer.offerid,
      action: "SIGNUP",
    };
    const onSuccess = () => {
      setOpenModal(true);
    };
    if (payload.userId && payload.offerid) {
      dispatch(sendSignUpOtp(payload, onSuccess, () => {}));
    }
  };

  const sendPayloadData = () => {
    const {
      customerName,
      slogan,
      PoIDetails,
      offerid,
      consentAccepted,
      address,
      pincode,
      city,
      state,
    } = customer;
    return {
      customer_name: customerName?.trim() || "",
      // mobile_no: mobile || "",
      unique_id: uniqueid || "",
      email_id: "",
      slogan: slogan || "",
      address: address || "",
      offer_id: offerid || "",
      city: city || "",
      state: state || "",
      pin_code: pincode || "",
      customer_consent: consentAccepted ? true : false,
      poi: PoIDetails?.value?.trim() || "",
      poi_type: PoIDetails.type || "",
      file: PoIDetails.file || "",
      fileName: PoIDetails.fileName || "",
    };
  };

  useEffect(() => {
    if (!profilePage && !schemeRegistrationFlag && fullState.signupSuccess) {
      navigate("/si");
    }
    if (profilePage && fullState.signupSuccess) {
      const {
        customerName,
        mobile,
        address,
        pincode,
        slogan,
        PoIDetails,
        city,
        state,
      } = customer;
      const storeData = {
        ...userData,
        customer_name: customerName,
        mobile_no: mobile,
        add_line1: address,
        slogan,
        PoIDetails,
        pin_code: pincode,
        city: city,
        state: state,
      };
      if (customerName && mobile) {
        localStorage.setItem("_userName", customerName);
        localStorage.setItem("_userData", JSON.stringify(storeData));
        setUserName(customerName);
      }
    }
  }, [fullState.signupSuccess, fullState.error]);

  useEffect(() => {
    if (schemeRegistrationFlag && fullState?.consent) {
      const {
        offername = "",
        min_eligibility = "",
        effectivefrom = "",
        effectivetill = "",
        website = "",
        slab_type = "",
        slogan_placeholder = "",
        form_type = "",
        offer_abbr = "",
      } = fullState?.consent;
      const consentInfo = {
        disCountType: offername,
        validFrom: effectivefrom,
        validTo: effectivetill,
        // isSloganEnabled: !!slogan?.trim()?.length,
        slogan_placeholder,
        form_type,
        offer_abbr,
        website: website,
        slabType: slab_type,
        slabLimit: min_eligibility,
      };
      setConsentData(consentInfo);
    }
  }, [fullState?.consent]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "customerName" || name === "city" || name === "state") {
      if (allowOnlyAlphabetsAndSpaces(value)) {
        setCustomer((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    } else if (name === "mobile") {
      if (allowOnlyNumbers(value)) {
        setCustomer((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    } else if (name === "slogan") {
      if (!consecutiveNewLineRegex.test(value)) {
        setCustomer((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    } else if (name === "pincode") {
      if (allowPincodeOrOtp(value)) {
        if (value.length === 6) {
          authApi
            .getStateByPincode(value)
            .then((userResponse) => {
              setCustomer((prevFormData) => ({
                ...prevFormData,
                city: userResponse?.data?.city ? userResponse?.data?.city : "",
                state: userResponse?.data?.state
                  ? userResponse?.data?.state
                  : "",
              }));
            })
            .catch((error) => {
              console.log("error=>", error);
            });
        }
        setCustomer((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    } else {
      setCustomer((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
    setError((prevErrorData) => ({
      ...prevErrorData,
      [name]: "",
    }));
  };

  const handlePOITypeChange = (newVal) => {
    setCustomer((prevFormData) => ({
      ...prevFormData,
      PoIDetails: {
        ...prevFormData.PoIDetails,
        isSelected: true,
        type: newVal,
      },
    }));
    setError((prevFormData) => ({
      ...prevFormData,
      PoIDetails: {
        ...prevFormData.PoIDetails,
        PoIerrorMsg: "",
      },
    }));
  };

  const handleFileChange = (event, name) => {
    const currentFile = event.target.files[0];
    if (currentFile && !allowedExtensions.exec(currentFile.name)) {
      setError((prevFormData) => ({
        ...prevFormData,
        PoIDetails: {
          ...prevFormData.PoIDetails,
          file: "File type not allowed. Please upload .png, .jpg or .jpeg images.",
        },
      }));
    } else if (currentFile && currentFile.size > 2 * 1024 * 1024) {
      setError((prevFormData) => ({
        ...prevFormData,
        PoIDetails: {
          ...prevFormData.PoIDetails,
          file: "File size exceeds 2MB limit",
        },
      }));
    } else {
      setError((prevFormData) => ({
        ...prevFormData,
        PoIDetails: {
          ...prevFormData.PoIDetails,
          file: "",
        },
      }));
    }
       // Reset the input using the ref
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
    setCustomer((prevFormData) => ({
      ...prevFormData,
      PoIDetails: {
        ...prevFormData.PoIDetails,
        file: currentFile,
        fileName: `${currentFile?.name}`,
      },
    }));
  };

  const handleRemoveFile = () => {
    setCustomer((prevFormData) => ({
      ...prevFormData,
      PoIDetails: {
        ...prevFormData.PoIDetails,
        file: "",
        fileName: "",
      },
    }));
  };

  const handlePOIFieldChange = (newVal) => {
    const correctNewVal =
      customer.PoIDetails.type === POI_TYPE.drivingLicense.key
        ? convertToUppercase(newVal)
        : newVal;
    setCustomer((prevFormData) => ({
      ...prevFormData,
      PoIDetails: {
        ...prevFormData.PoIDetails,
        value: correctNewVal,
      },
    }));
    setError((prevFormData) => ({
      ...prevFormData,
      PoIDetails: {
        ...prevFormData.PoIDetails,
        value: "",
      },
    }));
  };

  const allFormProps = {
    classes,
    consentData,
    handleChange,
    customer,
    setCustomer,
    setOpen,
    handleClickOpen,
    error,
    schemeRegistrationFlag,
    profilePage,
    handlePOITypeChange,
    handlePOIFieldChange,
    handleFileChange,
    handleRemoveFile,
    fileInputRef
  };

  return (
    <>
      <MenuBar
        sideBar={!schemeRegistrationFlag}
        title={
          schemeRegistrationFlag
            ? "Customer Scheme Registration"
            : profilePage
            ? "Customer Profile"
            : "Customer Registration"
        }
        userName={userName}
      />

      {consentData.form_type && (
        <Box className={classes.customerRegistrationCard}>
          <Card elevation={3} sx={{ maxWidth: 900 }}>
            <CardContent>
              <CardHeader
                title={
                  schemeRegistrationFlag
                    ? // "Customer Scheme Registration"
                      `${consentData?.disCountType ?? ""} Registration`
                    : profilePage
                    ? "Customer Profile"
                    : "Customer Registration"
                }
              />
              <Divider />
              {schemeRegistrationFlag && fullState.signupSuccess ? (
                <Box className={classes.thankyou}>
                  <Box className={classes.CheckIcon}>
                    <CheckCircleIcon fontSize="inherit" />
                  </Box>
                  <Box>Thank you for registration.</Box>
                </Box>
              ) : (
                <>
                  {consentData.form_type === FORM_TYPE.slogan ? (
                    <SloganForm {...allFormProps} />
                  ) : null}
                  {consentData.form_type === FORM_TYPE.full ? (
                    <FullRegistrationForm {...allFormProps} />
                  ) : null}

                  <Box className={classes.registerButton}>
                    <Button
                      variant="contained"
                      onClick={registerCustomer}
                      disabled={fullState.signupPending}
                    >
                      {profilePage ? "Update" : "Proceed"}
                    </Button>
                  </Box>
                </>
              )}
            </CardContent>
          </Card>
        </Box>
      )}

      <CustomerConsent
        open={open}
        handleClose={handleClose}
        consentAgree={consentAgree}
        data={consentData}
        isCustomerSchemeConsent={!!schemeRegistrationFlag}
      />
      <VerifyMobileNoModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        mobileNo={customer.mobile}
        uniqueid={uniqueid}
        offerId={customer.offerid}
        payloadData={sendPayloadData()}
      />
      <Backdrop
        className={classes.backdrop}
        open={
          fullState.signupPending ||
          (schemeRegistrationFlag && !consentData?.disCountType) ||
          false
        }
      >
        <CircularProgress style={{ color: "#fff" }} />
      </Backdrop>
    </>
  );
};

export default CustomerSchemeRegistration;
