import { bpclProfiles } from "./../config";
import auth from "../modules/auth";
const bpclProfileHost = bpclProfiles.host;

class authApi {
  static sendOtp(userParams, isDealer) {
    const endPoint = isDealer ? 'sendDealerOtp' : 'sendCustomerOtp'
    return fetchWithInterceptor(`${bpclProfileHost}/${endPoint}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(userParams),
    })
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }

  static verifyOtp(userParams, isDealer) {
    const endPoint = isDealer ? 'verifyDealerOtp' : 'verifyCustomerOtp'
    return fetchWithInterceptor(`${bpclProfileHost}/${endPoint}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(userParams),
    })
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }

  static logout() {
    return fetchWithInterceptor(`${bpclProfileHost}/logout`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }

  static customerLogout() {
    return fetchWithInterceptor(`${bpclProfileHost}/customerLogout`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }

  static getSmsDetails(userParams) {
    return fetchWithInterceptor(
      `${bpclProfileHost}/user_sms_details?unique_id=${userParams}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    )
      .then((response) => auth.verifyResponse(response))
      .then((json) => json)
      .catch((err) => err);
  }
}

export default authApi;
