import { useState, useEffect } from "react";

export function useDevToolsLogout(logoutFunction) {
  const [isDevToolsOpen, setIsDevToolsOpen] = useState(false);

  useEffect(() => {
    const detectDevTools = () => {
      const threshold = 160;
      const widthDifference = window.outerWidth - window.innerWidth;
      const heightDifference = window.outerHeight - window.innerHeight;

      // More reliable checks can be implemented
      let isOpen = widthDifference > threshold || heightDifference > threshold;

      // Image object trick
      const element = new Image();
      Object.defineProperty(element, "id", {
        get: () => {
          isOpen = true;
          return "Detected";
        },
      });

      console.dir(element);

      // Store detection in session storage to prevent logout on refresh
      const sessionDetectedDevTools = sessionStorage.getItem("devToolsOpen") === "true";

      if (isOpen !== (isDevToolsOpen || sessionDetectedDevTools)) {
        setIsDevToolsOpen(isOpen);
        sessionStorage.setItem("devToolsOpen", isOpen);

        if (isOpen && logoutFunction) {
          logoutFunction();
        }
      }
    };

    const handleResize = () => detectDevTools();
    
    detectDevTools();

    window.addEventListener("resize", handleResize);
    document.addEventListener("visibilitychange", detectDevTools);

    const checkInterval = setInterval(detectDevTools, 300);

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("visibilitychange", detectDevTools);
      clearInterval(checkInterval);
    };
  }, [isDevToolsOpen, logoutFunction]);
}