import { Button, Grid, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  signup,
  sendSignUpOtp
} from "../../../actions";
import authApi from "../../../api/login";
import DialogComponent from "../../../components/global/dialog";
import { allowPincodeOrOtp } from "../../../utils";

import { useDispatch, useSelector } from "react-redux";
import BackDropWrapper from "../../../components/global/BackDropWrapper";

const INITIAL_ERROR = {
  otp: "",
};

const VerifyMobileNoModal = ({
  openModal,
  setOpenModal,
  mobileNo,
  uniqueid,
  offerId,
  payloadData,
}) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [error, setError] = useState(INITIAL_ERROR);
  const [showTimer, setShowTimer] = useState(false);
  const initialSeconds = 300;
  const countdownRef = useRef(initialSeconds);
  const startTime = useRef(null);
  const spanRef = useRef(null);
  const fullState = useSelector((state) => state.signup);


  /**
   * Trigger send otp when pop up opens
   */
  useEffect(() => {
    if (openModal) {
      setIsOtpSent(true);
      startTimer();
    }
  }, [openModal]);

  const handleClose = () => {
    setOpenModal(false);
    setOtp("");
  };

  const modalDetails = {
    modalHeader: "Verify Mobile Number",
    actionButtonLabel: "Verify & Register",
  };

  const handleSendOtp = () => {
    const payload = {
      userId: uniqueid,
      offerid: offerId,
      action: "SIGNUP",
    };
    const onSuccess = () => {
      setIsOtpSent(true);
      startTimer();
      setLoading(false);
    }

    const onError = () => {
      setLoading(false);
    }
    setLoading(true);
    dispatch(sendSignUpOtp(payload, onSuccess, onError ))
  };

  const handleOtpChange = (event) => {
    setError(INITIAL_ERROR);
    const value = event.target.value;
    if (allowPincodeOrOtp(value)) {
      setOtp(value);
    }
  };

  const startTimer = () => {
      countdownRef.current = initialSeconds;
      startTime.current = new Date();
      if (spanRef.current) {
        spanRef.current.textContent = initialSeconds;
      }
      setShowTimer(true);
  };

  const handleRegisterButtonClick = () => {
    let isError = false;
    let errorObj = Object.assign({}, INITIAL_ERROR);
    if (!otp) {
      isError = true;
      errorObj.otp = "Otp is Required.";
    } else if (otp.length !== 6) {
      isError = true;
      errorObj.otp = "Please enter a valid OTP of 6 digits!";
    } else {
      isError = false;
      errorObj.otp = "";
    }

    if (!!isError) {
      setError(errorObj);
      return;
    } else {
      // verify & submit button API call
      const payload = {
        ...payloadData,
        otp: otp,
      };
      dispatch(signup(payload, handleClose));
      setOtp("");
    }
  };

  useEffect(() => {
    let intervalId;
    if (showTimer) {
      intervalId = setInterval(() => {
        const currentTime = new Date();
        let timeDiff = currentTime - startTime.current;
        //time difference in seconds
        timeDiff = Math.floor(timeDiff / 1000);
        countdownRef.current = initialSeconds - timeDiff;
        if (countdownRef.current <= 0) {
          startTime.current = null;
          clearInterval(intervalId);
          setShowTimer(false);
        }
        if (spanRef.current) {
          spanRef.current.textContent = `${Math.floor(
            countdownRef.current / 60
          )}:${
            countdownRef.current % 60 < 10
              ? `0${countdownRef.current % 60}`
              : countdownRef.current % 60
          }`;
        }
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [showTimer]);

  return (
    <DialogComponent
      open={openModal}
      maxWidth={"xs"}
      fullWidth={true}
      hideFullScreenOption={true}
      handleClose={handleClose}
      modalDetails={modalDetails}
      onUserActionSubmitHandler={handleRegisterButtonClick}
      isActionButtonDisabled={!isOtpSent}
    >
      <BackDropWrapper isLoading={fullState.signupPending}>
        <Grid
          container
          spacing={3}
          sx={{
            padding: "10px 10px",
          }}
        >
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <TextField
              label="Mobile Number"
              value={mobileNo}
              type="tel"
              inputProps={{
                maxLength: 10,
              }}
              disabled={true} // need to disable it
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSendOtp}
              disabled={!mobileNo || isOtpSent}
            >
              Send OTP
            </Button>
          </Grid>

          {isOtpSent && (
            <>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <TextField
                  required
                  label="OTP"
                  value={otp}
                  onChange={handleOtpChange}
                  inputProps={{
                    maxLength: 6,
                  }}
                  type="tel"
                  error={!!error.otp}
                  helperText={!!error.otp ? error.otp : ""}
                />
              </Grid>

              {showTimer ? (
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  {" "}
                  <p>
                    Please wait <span ref={spanRef}>{initialSeconds}</span>{" "}
                    minutes to resend OTP
                  </p>
                </Grid>
              ) : (
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSendOtp}
                  >
                    Resend OTP
                  </Button>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </BackDropWrapper>
    </DialogComponent>
  );
};

export default VerifyMobileNoModal;
