/* eslint-disable */
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import reducers from "../reducers";

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleWare = routerMiddleware(history);
const middleware = [thunk, sagaMiddleware, routeMiddleWare];
import * as types from "../actions/actionTypes";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { persistReducer, persistStore } from "redux-persist";

const appReducer = combineReducers({
  ...reducers,
  router: connectRouter(history),
});

const rootReducer = (state, action) => {
  if (action.type === types.LOGOUT_SUCCESS) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const initialState = {};
const enhancers = [];

if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

// Configure persist options for each reducer you want to persist
const persistConfig = {
  key: "root",
  storage,
  whitelist: [], // Specify the reducers you want to persist
};

// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(
  connectRouter(history)(persistedReducer),
  initialState,
  composedEnhancers
);

// Create persistor (to be passed to the persistGate)
const persistor = persistStore(store);

export default {
  store,
  history,
  persistor,
};
