import {
  allowedExtensions,
  alphanumericWhitespaceRegex,
  isValidMobileNumber,
  licenseRegex,
} from "../../../utils";

export const FORM_TYPE = {
  full: "full",
  slogan: "slogan",
};

export const POI_TYPE = {
  drivingLicense: {
    label: "Driving License",
    key: "drivingLicense",
    placeholder: "DL-1420110012345",
  },
};

export const fullFormValidation = (customer, errorObj, profilePage) => {
  let isError = false;
  const { customerName, mobile, PoIDetails, consentAccepted, pincode } =
    customer;

  if (!customerName || !customerName?.trim()) {
    isError = true;
    errorObj.customerName = "Customer Name is Required.";
  } else {
    if (!alphanumericWhitespaceRegex.test(customerName)) {
      isError = true;
      errorObj.customerName = "Name must contain only alphanumeric characters.";
    } else {
      errorObj.customerName = "";
    }
  }

  if (!mobile) {
    isError = true;
    errorObj.mobile = "Mobile number is required.";
  } else if (!isValidMobileNumber(mobile)) {
    isError = true;
    errorObj.mobile = "Invalid mobile number.";
  } else {
    errorObj.mobile = "";
  }

  if (!pincode || !pincode?.trim()) {
    isError = true;
    errorObj.pincode = "Pincode is required.";
  } else if (pincode.length !== 6) {
    isError = true;
    errorObj.pincode = "Invalid pincode number.";
  } else {
    errorObj.pincode = "";
  }

  // if (!PoIDetails.type) {
  //   isError = true;
  //   errorObj.PoIDetails.PoIerrorMsg = "Proof of Identification is required.";
  // } else {
  //   errorObj.PoIDetails.PoIerrorMsg = "";
  // }

  if (PoIDetails.type && PoIDetails.type === POI_TYPE.drivingLicense.key) {
    // check DL number
    if (PoIDetails?.value?.trim() && !licenseRegex.test(PoIDetails?.value?.trim())) {
      isError = true;
      errorObj.PoIDetails.value = "Driving License Number is Invalid.";
    } else {
      errorObj.PoIDetails.value = "";
    }

    // check DL file
    if (!!PoIDetails.file) {
      if (!allowedExtensions.exec(PoIDetails.file.name)) {
        isError = true;
        errorObj.PoIDetails.file =
          "File type not allowed. Please upload .png, .jpg or .jpeg images.";
      } else if (PoIDetails.file.size > 2 * 1024 * 1024) {
        isError = true;
        errorObj.PoIDetails.file = "File size exceeds 2MB limit";
      } else {
        errorObj.PoIDetails.file = "";
      }
    }
  }

  if (!profilePage && !consentAccepted) {
    isError = true;
    errorObj.consentAccepted = "Please accept terms and conditions";
  } else {
    errorObj.consentAccepted = "";
  }

  return isError;
};

export const sloganFormValidation = (customer, errorObj, profilePage) => {
  let isError = false;
  const { customerName, mobile, slogan, consentAccepted } = customer;

  if (!customerName || !customerName?.trim()) {
    isError = true;
    errorObj.customerName = "Customer Name is Required.";
  } else {
    if (!alphanumericWhitespaceRegex.test(customerName)) {
      isError = true;
      errorObj.customerName = "Name must contain only alphanumeric characters.";
    } else {
      errorObj.customerName = "";
    }
  }

  if (!mobile) {
    isError = true;
    errorObj.mobile = "Mobile number is required.";
  } else if (!isValidMobileNumber(mobile)) {
    isError = true;
    errorObj.mobile = "Invalid mobile number.";
  } else {
    errorObj.mobile = "";
  }

  if (slogan) {
    if (!slogan.trim().length) {
      isError = true;
      errorObj.slogan = "slogan contains only whitespaces.";
    } else {
      // if (!alphanumericWhitespaceRegex.test(slogan)) {
      //   isError = true;
      //   errorObj.slogan = "Slogan must contain only alphanumeric characters.";
      // }
      errorObj.slogan = "";
    }
  } else {
    errorObj.slogan = "";
  }

  if (!profilePage && !consentAccepted) {
    isError = true;
    errorObj.consentAccepted = "Please accept terms and conditions";
  } else {
    errorObj.consentAccepted = "";
  }

  return isError;
};
