import { useEffect, useRef, useState } from "react";
import { INIT_ERROR_OBJ } from "./dashboard.utils";
import {
  getVoucherDetails,
  getVoucherHistory,
  verifyVoucherAndRedeem,
} from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import * as types from "../../actions/actionTypes";
import { allowedExtensions } from "../../utils";

const INITIAL_FILE = {
  file: "",
  fileName: "",
}

export const useDashboard = () => {
  const [openRedeemModal, setOpenRedeemModal] = useState(false);
  const [voucherCode, setVoucherCode] = useState("");
  const [txnId, setTxnId] = useState("");
  const [txnAttachment, setTxnAttachment] = useState(INITIAL_FILE);

  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const [otpValue, setOtpValue] = useState("");
  const [errorObj, setErrorObj] = useState({ ...INIT_ERROR_OBJ });
  const isVoucherRedeemed = false;
  const [showTimer, setShowTimer] = useState(false);
  const initialSeconds = 300;
  const countdownRef = useRef(initialSeconds);
  const spanRef = useRef(null);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const { voucher, voucherHistory, isLoading } = useSelector(
    (state) => state.redemption
  );

  useEffect(() => {
    dispatch(getVoucherHistory({}));
  }, []);

  useEffect(() => {
    if (voucher) {
      startTimer();
    }
  }, [voucher]);

  useEffect(() => {
    let intervalId;
    if (showTimer) {
      intervalId = setInterval(() => {
        countdownRef.current -= 1;
        if (countdownRef.current <= 0) {
          setSelectedVoucher(null);
          setVoucherCode("");
          setTxnId("");
          setOtpValue("");
          setErrorObj({ ...INIT_ERROR_OBJ });
          dispatch({ type: types.RESET_VOUCHER });
          clearInterval(intervalId);
          setShowTimer(false);
          setTxnAttachment(INITIAL_FILE);
        }
        if (spanRef.current) {
          spanRef.current.textContent = `${Math.floor(
            countdownRef.current / 60
          )}:${
            countdownRef.current % 60 < 10
              ? `0${countdownRef.current % 60}`
              : countdownRef.current % 60
          }`;
        }
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [showTimer]);

  const startTimer = () => {
    if (!showTimer) {
      countdownRef.current = initialSeconds;
      if (spanRef.current) {
        spanRef.current.textContent = initialSeconds;
      }
      setShowTimer(true);
    }
  };

  const onRedeemModalClose = () => {
    setOpenRedeemModal(false);
    setSelectedVoucher(null);
    setVoucherCode("");
    setTxnId("");
    setOtpValue("");
    setErrorObj({ ...INIT_ERROR_OBJ });
    dispatch({ type: types.RESET_VOUCHER });
    setShowTimer(false);
    setTxnAttachment(INITIAL_FILE);
  };

  const onVoucherCodeChange = (value) => {
    setErrorObj((prevState) => ({
      ...prevState,
      voucherCode: INIT_ERROR_OBJ.voucherCode,
    }));
    // Remove any characters that are not alphanumeric (including spaces)
    const cleanedValue = value.replace(/[^a-zA-Z0-9]/g, "");
    setVoucherCode(cleanedValue);
  };

  const handleFileChange = (event, name) => {
    try {
      const currentFile = event.target.files[0];
      if (currentFile && !allowedExtensions.exec(currentFile.name)) {
        setErrorObj((prevState) => ({
          ...prevState,
          txnAttachment:
            "File type not allowed. Please upload .png, .jpg, .pdf or .jpeg file.",
        }));
      } else if (currentFile && currentFile.size > 2 * 1024 * 1024) {
        setErrorObj((prevState) => ({
          ...prevState,
          txnAttachment: "File size exceeds 2MB limit",
        }));
      } else {
        setErrorObj((prevState) => ({
          ...prevState,
          txnAttachment: "",
        }));
      }
      // Reset the input using the ref
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      setTxnAttachment((prevFormData) => ({
        file: currentFile,
        fileName: currentFile?.name,
      }));
    } catch (err) {
      console.log("~ handleFileChange ~ err:", err);
    }
  };

  const handleRemoveFile = () => {
    setTxnAttachment(() => INITIAL_FILE);
  };

  const onTxnIdChange = (value) => {
    // Remove any characters that are not alphanumeric (including spaces)
    const cleanedValue = value.replace(/[^a-zA-Z0-9_]/g, "");

    setErrorObj((prevState) => ({
      ...prevState,
      txnId: INIT_ERROR_OBJ.txnId,
    }));

    setTxnId(cleanedValue);
  };

  const onValidateVoucher = () => {
    if (!voucherCode || !voucherCode.length) {
      setErrorObj((prevState) => ({
        ...prevState,
        voucherCode: "This field is required!",
      }));
      return;
    }
    setSelectedVoucher(voucherCode);
    const payload = {
      voucherCode: voucherCode,
    };
    const onSuccess = (res) => {};
    const onError = () => {
      setSelectedVoucher("");
    };

    dispatch(getVoucherDetails(payload, onSuccess, onError));
    console.log("!!Validate Voucher button press");
  };

  const handleOtpChange = (value) => {
    setOtpValue(value);
  };

  const handleOtpVerify = () => {
    console.log("!! verify otp pressed", otpValue);
    const repeatingNumbersRegex =
      /(0000)|(1111)|(2222)|(3333)|(4444)|(5555)|(6666)|(7777)|(8888)|(9999)/;
    let isError = false;
    // Check if txnId is not null and has no error in errorObj
    if (!txnId) {
      isError = true;
      // Set the error message when txnId is null or empty
      setErrorObj((prevState) => ({
        ...prevState,
        txnId: "Transaction ID is required!",
      }));
      return; // Exit early if txnId is null or empty
    } else if (repeatingNumbersRegex.test(txnId)) {
      isError = true;
      setErrorObj((prevState) => ({
        ...prevState,
        txnId: "Transaction ID is not valid!",
      }));
    }
    if (isError || errorObj.txnId) {
      // If there is an existing error related to txnId, do not proceed
      return;
    }
    isError = false

    const payload = {
      voucherCode: voucherCode,
      otp: otpValue,
      txnid: txnId,
      file: txnAttachment?.file || "",
      fileName: txnAttachment?.fileName || "",
    };

    const onSuccess = (res) => {
      dispatch(getVoucherHistory({}));
      setOpenRedeemModal(false);
    };

    const onError = () => {};

    dispatch(verifyVoucherAndRedeem(payload, onSuccess, onError));
  };

  return {
    openRedeemModal,
    setOpenRedeemModal,
    onRedeemModalClose,
    errorObj,
    voucherCode,
    onVoucherCodeChange,
    onValidateVoucher,
    selectedVoucher,
    otpValue,
    handleOtpChange,
    handleOtpVerify,
    txnId,
    onTxnIdChange,
    isVoucherRedeemed,
    voucher,
    voucherHistory,
    showTimer,
    spanRef,
    initialSeconds,
    isLoading,
    setVoucherCode,
    txnAttachment,
    handleFileChange,
    handleRemoveFile,
    setSelectedVoucher,
    fileInputRef,
  };
};
