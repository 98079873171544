import React, { useEffect, useRef, useState } from "react";
import {
  TextField,
  Grid,
  Button,
  MenuItem,
  IconButton,
  Divider,
  Checkbox,
  Box,
  Card,
  CardContent,
  CardHeader,
  FormHelperText,
  FormControl,
  FormControlLabel,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CustomerConsent from "./consent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { signup, updateProfile, getConsentDetails } from "../../../actions";
import MenuBar from "../../menubar";
import authApi from "../../../api/signup";
import {
  toPascalCase,
  allowOnlyNumbers,
  allowPincodeOrOtp,
  isValidMobileNumber,
  isValidVehicleNumber,
  isValidBharatSeriesNumber,
} from "../../../utils";
import { getValue } from "../../../utils/encryption";

const useStyles = makeStyles({
  customerRegistrationCard: {
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "120px 15px 20px",
  },
  textField: {
    margin: 0,
    padding: 0,
    width: "100%",
  },
  registrationFormMain: {
    marginTop: 32,
  },
  consent: {
    marginTop: 16,
    display: "flex",
    alignItems: "center",
  },
  registerButton: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  vehicleSection: {
    display: "flex",
    alignItems: "center",
  },
  consentLink: {
    cursor: "pointer",
    paddingLeft: 5,
    color: "red",
  },
  consentError: {
    marginLeft: 20,
    marginTop: -10,
  },
  thankyou: {
    height: 200,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 20,
    color: "rgb(46, 125, 50)",
    fontWeight: "bold",
  },
  CheckIcon: {
    fontSize: 60,
    marginBottom: 10,
  },
});

const INITIAL_ERROR = {
  customerName: "",
  mobile: "",
  pincode: "",
  consentAccepted: "",
  vehicleDetails: [],
};

const INITIAL_STATE = {
  customerName: "",
  mobile: "",
  address: "",
  vehicleDetails: [
    {
      vehicleType: "",
      registration_no: "",
    },
  ],
  pincode: "",
  city: "",
  state: "",
  consentAccepted: false,
};

const SignUp = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { uniqueid } = useParams();
  const fullState = useSelector((state) => state.signup);
  const dispatch = useDispatch();
  const schemeRegistrationFlag = location.pathname.split("/").includes("sr");
  const profilePage = location.pathname === "/profile";
  const [customer, setCustomer] = useState(INITIAL_STATE);
  const [error, setError] = useState(INITIAL_ERROR);
  const [open, setOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [consentData, setConsentData] = useState("");
  const userData = getValue("_userData") && JSON.parse(getValue("_userData"));

  useEffect(() => {
    if (uniqueid && schemeRegistrationFlag) {
      authApi
        .getSmsDetails(uniqueid)
        .then((smsDetails) => {
          setCustomer((prevFormData) => ({
            ...prevFormData,
            mobile: smsDetails?.data?.mobile_number,
            offerid: smsDetails?.data?.offer_id,
            vehicleDetails: [
              {
                registration_no: smsDetails?.data?.vehicle_number,
              },
            ],
          }));
        })
        .catch((error) => {
          console.log("error=>", error);
        });
    }
  }, [uniqueid]);

  useEffect(() => {
    if (customer.offerid) {
      dispatch(getConsentDetails(customer.offerid));
      localStorage.setItem("_offerid", customer.offerid);
    }
  }, [customer.offerid]);

  useEffect(() => {
    setUserName(userData?.customer_name);
    if (profilePage && !!userData) {
      setCustomer((prevFormData) => ({
        ...prevFormData,
        mobile: userData?.mobile_no || "",
        customerName: userData?.customer_name || "",
        mobile: userData?.mobile_no || "",
        city: userData?.city || "",
        state: userData?.state || "",
        pincode: userData?.pin_code || "",
        address: userData?.add_line1 || "",
        vehicleDetails: [
          {
            vehicleType: userData?.vehicles[0]?.vehicle_type || "",
            registration_no: userData?.vehicles[0]?.registration_no || "",
          },
        ],
      }));
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const consentAgree = () => {
    setOpen(false);
    setCustomer((prevFormData) => ({
      ...prevFormData,
      consentAccepted: true,
    }));
    setError((prevErrorData) => ({
      ...prevErrorData,
      consentAccepted: "",
    }));
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const registerCustomer = () => {
    let errorObj = Object.assign({}, INITIAL_ERROR);

    const {
      customerName,
      mobile,
      vehicleDetails,
      consentAccepted,
      address,
      pincode,
      city,
      state,
    } = customer;

    if (!customerName) {
      errorObj.customerName = "Customer Name is Required.";
    } else {
      errorObj.customerName = "";
    }

    if (!mobile) {
      errorObj.mobile = "Mobile number is required.";
    } else if (!isValidMobileNumber(mobile)) {
      errorObj.mobile = "Invalid mobile number.";
    } else {
      errorObj.mobile = "";
    }

    for (var i = 0; i < vehicleDetails.length; i++) {
      errorObj.vehicleDetails[i] = {};
      if (!vehicleDetails[i].registration_no) {
        errorObj.vehicleDetails[i] = "Vehicle number is required.";
      } else if (
        vehicleDetails[i].registration_no &&
        !isValidVehicleNumber(vehicleDetails[i].registration_no) &&
        !isValidBharatSeriesNumber(vehicleDetails[i].registration_no)
      ) {
        errorObj.vehicleDetails[i] = "Invalid vehicle number.";
      } else {
        errorObj.vehicleDetails[i] = "";
      }
    }

    if (!pincode) {
      errorObj.pincode = "Pincode is required.";
    } else if (pincode.length !== 6) {
      errorObj.pincode = "Invalid pincode number.";
    } else {
      errorObj.pincode = "";
    }

    if (!profilePage && !consentAccepted) {
      errorObj.consentAccepted = "Please accept terms and conditions";
    } else {
      errorObj.consentAccepted = "";
    }

    const hasError = !Object.values(errorObj).every(
      (x) => x === "" || (Array.isArray(x) && x.every((v) => v === ""))
    );

    if (!!hasError) {
      setError(errorObj);
      return;
    } else {
      const payload = {
        customer_name: customerName,
        mobile_no: mobile,
        email_id: "",
        address: address,
        city: city,
        state: state,
        pin_code: pincode,
        customer_consent: consentAccepted ? 1 : 0,
        vehicles: vehicleDetails,
        regType: schemeRegistrationFlag ? "scheme" : "customer",
      };
      if (profilePage) {
        dispatch(updateProfile(payload));
      } else {
        dispatch(signup(payload));
      }
    }
  };

  useEffect(() => {
    if (!profilePage && !schemeRegistrationFlag && fullState.signupSuccess) {
      navigate("/si");
    }
    if (profilePage && fullState.signupSuccess) {
      const {
        customerName,
        mobile,
        vehicleDetails,
        address,
        pincode,
        city,
        state,
      } = customer;
      const storeData = {
        ...userData,
        customer_name: customerName,
        mobile_no: mobile,
        add_line1: address,
        vehicles: vehicleDetails,
        pin_code: pincode,
        city: city,
        state: state,
      };
      if (customerName && mobile) {
        localStorage.setItem("_userName", customerName);
        localStorage.setItem("_userData", JSON.stringify(storeData));
        setUserName(customerName);
      }
    }
  }, [fullState.signupSuccess, fullState.error]);

  useEffect(() => {
    if (schemeRegistrationFlag && fullState?.consent) {
      const {
        offername = "",
        min_eligibility = "",
        effectivefrom = "",
        effectivetill = "",
        website = "",
        slab_type = "",
      } = fullState?.consent;
      const consentInfo = {
        disCountType: offername,
        validFrom: effectivefrom,
        validTo: effectivetill,
        website: website,
        slabType: slab_type,
        slabLimit: min_eligibility,
      };
      setConsentData(consentInfo);
    }
  }, [fullState?.consent]);

  const addVehicle = () => {
    setCustomer((prevFormData) => ({
      ...prevFormData,
      vehicleDetails: [
        ...prevFormData.vehicleDetails,
        {
          vehicleType: "",
          registration_no: "",
        },
      ],
    }));
  };

  const removeVehicle = (index) => {
    const arr = [...customer.vehicleDetails];
    if (arr.length > 1) {
      arr.splice(index, 1);
      setCustomer((prevFormData) => ({
        ...prevFormData,
        vehicleDetails: arr,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile") {
      if (allowOnlyNumbers(value)) {
        setCustomer((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    } else if (name === "pincode") {
      if (allowPincodeOrOtp(value)) {
        if (value.length === 6) {
          authApi
            .getStateByPincode(value)
            .then((userResponse) => {
              setCustomer((prevFormData) => ({
                ...prevFormData,
                city: userResponse?.data?.city ? userResponse?.data?.city : "",
                state: userResponse?.data?.state
                  ? userResponse?.data?.state
                  : "",
              }));
            })
            .catch((error) => {
              console.log("error=>", error);
            });
        }
        setCustomer((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    } else {
      setCustomer((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    setError((prevErrorData) => ({
      ...prevErrorData,
      [name]: "",
    }));
  };

  const updateFieldChanged = (index) => (e) => {
    let newArr = [...customer.vehicleDetails];
    let errArr = [...error.vehicleDetails];
    newArr[index][e.target.name] = e.target.value;
    if (errArr.length && errArr[index] && e.target.name === "registration_no") {
      errArr[index] = "";
    }
    setCustomer((prevFormData) => ({
      ...prevFormData,
      vehicleDetails: newArr,
    }));
    setError((prevErrorData) => ({
      ...prevErrorData,
      vehicleDetails: errArr,
    }));
  };

  return (
    <>
      <MenuBar
        sideBar={!schemeRegistrationFlag}
        title={
          schemeRegistrationFlag
            ? "Customer Scheme Registration"
            : profilePage
            ? "Customer Profile"
            : "Customer Registration"
        }
        userName={userName}
      />
      <Box className={classes.customerRegistrationCard}>
        <Card elevation={3} sx={{ maxWidth: 900 }}>
          <CardContent>
            <CardHeader
              title={
                schemeRegistrationFlag
                  ? "Customer Scheme Registration"
                  : profilePage
                  ? "Customer Profile"
                  : "Customer Registration"
              }
            />
            <Divider />
            {schemeRegistrationFlag && fullState.signupSuccess ? (
              <Box className={classes.thankyou}>
                <Box className={classes.CheckIcon}>
                  <CheckCircleIcon fontSize="inherit" />
                </Box>
                <Box>Thank you for registration.</Box>
              </Box>
            ) : (
              <>
                <Grid
                  container
                  columnSpacing={2}
                  className={classes.registrationFormMain}
                >
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="standard-basic"
                      label="Customer Name"
                      className={classes.textField}
                      margin="normal"
                      name="customerName"
                      onChange={handleChange}
                      value={customer.customerName}
                      error={!!error.customerName}
                      helperText={
                        !!error.customerName ? "Customer Name Required." : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="standard-basic"
                      label="Mobile Number"
                      className={classes.textField}
                      margin="normal"
                      name="mobile"
                      type="tel"
                      inputProps={{
                        maxLength: 10,
                      }}
                      onChange={handleChange}
                      disabled={schemeRegistrationFlag || profilePage}
                      value={customer.mobile}
                      error={!!error.mobile}
                      helperText={!!error.mobile ? error.mobile : ""}
                    />
                  </Grid>
                  {customer?.vehicleDetails?.map((vehicle, idx) => (
                    <Grid
                      item
                      container
                      xs={12}
                      spacing={2}
                      className={classes.vehicleSection}
                      key={idx}
                    >
                      <Grid
                        item
                        xs={schemeRegistrationFlag || profilePage ? 6 : 5}
                        md={6}
                      >
                        <TextField
                          id="standard-vehicle-type"
                          select
                          defaultValue={customer.preferredChannel}
                          label="Vehicle Type"
                          className={classes.textField}
                          value={vehicle.vehicleType}
                          name="vehicleType"
                          disabled={profilePage} // Need to correct once backend changes done
                          margin="normal"
                          onChange={updateFieldChanged(idx)}
                        >
                          <MenuItem value={"twoWheeler"}>Two Wheeler</MenuItem>
                          <MenuItem value={"threeWheeler"}>
                            Three Wheeler
                          </MenuItem>
                          <MenuItem value={"fourWheeler"}>
                            Four Wheeler
                          </MenuItem>
                          <MenuItem value={"commercial"}>
                            Commercial Vehicle
                          </MenuItem>
                        </TextField>
                      </Grid>
                      <Grid
                        item
                        xs={schemeRegistrationFlag || profilePage ? 6 : 5}
                      >
                        <TextField
                          required
                          id="standard-required"
                          label="Vehicle Number"
                          placeholder="HR01AB2233"
                          className={classes.textField}
                          margin="normal"
                          value={vehicle.registration_no?.toUpperCase()}
                          name="registration_no"
                          disabled={
                            idx === 0 && (schemeRegistrationFlag || profilePage)
                          }
                          onChange={updateFieldChanged(idx)}
                          error={
                            error.vehicleDetails[idx] &&
                            error.vehicleDetails[idx]
                          }
                          helperText={
                            error.vehicleDetails[idx] &&
                            error.vehicleDetails[idx]
                              ? error.vehicleDetails[idx]
                              : ""
                          }
                        />
                      </Grid>
                      {!schemeRegistrationFlag && !profilePage && (
                        <Grid item xs={2} md={1}>
                          {customer.vehicleDetails.length - 1 === idx ? (
                            <IconButton onClick={addVehicle}>
                              <AddIcon />
                            </IconButton>
                          ) : (
                            <IconButton onClick={() => removeVehicle(idx)}>
                              <RemoveIcon />
                            </IconButton>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  ))}
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="standard-basic"
                      label="House No/ Street Address"
                      multiline
                      maxRows={2}
                      className={`${classes.textField} ${classes.textArea}`}
                      margin="normal"
                      name="address"
                      onChange={handleChange}
                      value={customer.address}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="standard-basic"
                      label="Pincode"
                      className={classes.textField}
                      margin="normal"
                      name="pincode"
                      type="tel"
                      inputProps={{
                        maxLength: 6,
                      }}
                      onChange={handleChange}
                      value={customer.pincode}
                      error={!!error.pincode}
                      helperText={!!error.pincode ? error.pincode : ""}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="standard-basic"
                      label="City"
                      className={classes.textField}
                      margin="normal"
                      name="city"
                      onChange={handleChange}
                      value={toPascalCase(customer?.city)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="standard-basic"
                      label="State"
                      className={classes.textField}
                      margin="normal"
                      name="state"
                      onChange={handleChange}
                      value={toPascalCase(customer?.state)}
                    />
                  </Grid>
                  {!profilePage && (
                    <Grid item xs={12}>
                      <Box className={classes.consent}>
                        <FormControl error={!!error} component="fieldset">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={customer.consentAccepted}
                                onChange={() =>
                                  customer.consentAccepted
                                    ? setCustomer((prevFormData) => ({
                                        ...prevFormData,
                                        consentAccepted:
                                          !customer.consentAccepted,
                                      }))
                                    : setOpen(true)
                                }
                                name="consentAccepted"
                                color="primary"
                              />
                            }
                            label={
                              <>
                                I agree to the
                                <span
                                  onClick={handleClickOpen}
                                  className={classes.consentLink}
                                  color="primary"
                                >
                                  Terms and Conditions
                                </span>
                              </>
                            }
                          />
                          {error.consentAccepted && (
                            <Box className={classes.consentError}>
                              <FormHelperText>
                                {error.consentAccepted}
                              </FormHelperText>
                            </Box>
                          )}
                        </FormControl>
                      </Box>
                    </Grid>
                  )}
                </Grid>
                <Box className={classes.registerButton}>
                  <Button
                    variant="contained"
                    onClick={registerCustomer}
                    disabled={fullState.signupPending}
                  >
                    {profilePage ? "Update" : "Register"}
                  </Button>
                </Box>
              </>
            )}
          </CardContent>
        </Card>
      </Box>
      <CustomerConsent
        open={open}
        handleClose={handleClose}
        consentAgree={consentAgree}
        data={consentData}
        isCustomerSchemeConsent={!!schemeRegistrationFlag}
      />
      <Backdrop
        className={classes.backdrop}
        open={
          fullState.signupPending ||
          (schemeRegistrationFlag && !consentData?.disCountType) ||
          false
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default SignUp;
