import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function snackbarReducer(state = initialState.snackbar, action) {
  switch (action.type) {
    case types.CLOSE_SNACKBAR:
      return {
        open: false,
      };
    case types.OPEN_SUCCESS_SNACKBAR:
      return {
        open: true,
        message: action.snackbarData.message || action.snackbarData.error || "",
        severity: "success",
      };
    case types.OPEN_ERROR_SNACKBAR:
      return {
        open: true,
        message: action.snackbarData.message || action.snackbarData.error || "",
        severity: "error",
      };
    default:
      return state;
  }
}
