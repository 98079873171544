export const LOGIN_INITIATED = "all.login_initiated";
export const LOGIN_SUCCESS = "all.login_success";
export const LOGIN_FAILURE = "all.login_failure";
export const LOGIN_INVALID_CREDS = "all.login_failed_invalid_credentials";
export const SEND_OTP_SUCCESS = "all.send_otp_success";
export const UPDATE_SEND_OTP_SUCCESS = "all.update_send_otp_success";
export const REQUEST_PASSWORD_INITIATED = "all.request_password_initiated";
export const REQUEST_PASSWORD_SUCCESS = "all.request_password_success";
export const REQUEST_PASSWORD_FAILURE = "all.request_password_failure";
export const VALIDATE_TOKEN_INITIATED = "all.validate_token_initiated";
export const VALIDATE_TOKEN_SUCCESS = "all.validate_token_success";
export const VALIDATE_TOKEN_FAILURE = "all.validate_token_failure";
export const RESET_PASSWORD_INITIATED = "all.reset_password_initiated";
export const RESET_PASSWORD_SUCCESS = "all.reset_password_success";
export const RESET_PASSWORD_FAILURE = "all.reset_password_failure";
export const CHANGE_PASSWORD_INITIATED = "all.change_password_initiated";
export const CHANGE_PASSWORD_SUCCESS = "all.change_password_success";
export const CHANGE_PASSWORD_FAILURE = "all.change_password_failure";
export const LOGOUT_INITIATED = "all.logout_initiated";
export const LOGOUT_SUCCESS = "all.logout_success";
export const LOGOUT_FAILURE = "all.logout_failure";
export const SIGNUP_INITIATED = "all.signup_initiated";
export const SIGNUP_SUCCESS = "all.signup_success";
export const SIGNUP_FAILURE = "all.signup_failure";
export const SIGNUP_OTP_INITIATED = "SIGNUP_OTP_INITIATED";
export const SIGNUP_OTP_DONE = "SIGNUP_OTP_DONE";


export const SIGNUP_INVALID_CREDS = "all.signup_failed_invalid_credentials";
export const GET_SCHEME_DETAILS_INITIATED = "all.get_scheme_initiated";
export const GET_SCHEME_DETAILS_SUCCESS = "all.get_scheme_success";
export const GET_SCHEME_DETAILS_FAILURE = "all.get_scheme_failure";
export const GET_SCHEME_DETAILS_INVALID_CREDS =
  "all.get_scheme_failed_invalid_credentials";
export const CLOSE_SNACKBAR = "all.close_snackbar";
export const OPEN_SUCCESS_SNACKBAR = "all.open_success_snackbar";
export const OPEN_ERROR_SNACKBAR = "all.open_error_snackbar";
export const GET_CONSENT_SUCCESS = "all.get_consent_success";

// Loading
export const INIT_REDEMPTION_LOADING = "INIT_REDEMPTION_LOADING";

// Fetching
export const STORE_REDEMPTION_SUCCESS = "STORE_REDEMPTION_SUCCESS";
export const STORE_REDEMPTION_ERROR = "STORE_REDEMPTION_ERROR";

export const INIT_VOUCHER_LOADING = "INIT_VOUCHER_LOADING";
export const STORE_VOUCHER_SUCCESS = "STORE_VOUCHER_SUCCESS";
export const STORE_VOUCHER_ERROR = "STORE_VOUCHER_ERROR";
export const RESET_VOUCHER = "RESET_VOUCHER";

export const INIT_VOUCHER_HISTORY_LOADING = "INIT_VOUCHER_HISTORY_LOADING";
export const STORE_VOUCHER_HISTORY_SUCCESS = "STORE_VOUCHER_HISTORY_SUCCESS";
export const STORE_VOUCHER_HISTORY_ERROR = "STORE_VOUCHER_HISTORY_ERROR";


export const INIT_REDEEM_VOUCHER_LOADING = "INIT_REDEEM_VOUCHER_LOADING";
export const INIT_REDEEM_VOUCHER_SUCCESS = "INIT_REDEEM_VOUCHER_SUCCESS";
export const INIT_REDEEM_VOUCHER__ERROR = "INIT_REDEEM_VOUCHER__ERROR";


