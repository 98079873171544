export default {
  user: [],
  redemption: {
    data: {},
    message: "",
    isLoading: true,
    variant: "",
    voucher: null,
    voucherHistory: [],
    isRedeemLoading: false
  },
  userdetails: [],
  dropdown: [],
  errorLogs: [],
  response: [],
  snackbar: {
    open: false,
    message: "",
    severity: "info",
  },
};
