const CryptoJS = require("crypto-js");
const reduxStore = require("../store");

export const KEY_MAPPER = {
  _token: "mhtk",
  _refreshToken: "mhrtk",
  _activityTime: "yhil",
  _userId: "mhui",
  _offerid: "mhoi",
  _userName: "mhun",
  _userData: "mhud",
  _userUniqueId: "mhuid"
};

let cachedKey = null; // Cache the derived key

const getSK = (uniqueKey = password) => {
  if (!cachedKey) {
    cachedKey = CryptoJS.PBKDF2(uniqueKey, "", { keySize: 256 / 32 }).toString();
  }
  return cachedKey;
};

const initVector = CryptoJS.enc.Hex.parse("0000000000000000");

export const encryptData = (obj = "") => {
  if (obj?.length) {
    try {

      const value = process.env.REACT_APP_ENC_KY;
      const key = getSK(value);
      const encrypted = CryptoJS.AES.encrypt(obj, key, { iv: initVector }).toString();
      return encrypted;
    } catch (err) {
      console.error("encrypt error", err.message, obj);
    }
  }
  return obj;
};

export const decryptData = (obj = "") => {
  if (obj?.length) {
    try {
      const value = process.env.REACT_APP_ENC_KY;
      const key = getSK(value);
      const decrypted = CryptoJS.AES.decrypt(obj, key, { iv: initVector }).toString(CryptoJS.enc.Utf8);
      return decrypted;
    } catch (err) {
      console.error("decrypt error", err.message, obj);
    }
  }
  return obj;
};

/**
 * Clear all cookies defined in COOKIES_KEYS asynchronously.
 */
export async function clearAllCookies() {
  localStorage.clear();
}

/**
 * Set a cookie with the specified name and encrypted value asynchronously.
 *
 * @param cname - The name of the cookie.
 * @param cvalue - The value of the cookie.
 */
export const setValue = (cname, cvalue) => {
  const name = KEY_MAPPER[cname];
  const encryptedValue = encryptData(cvalue);
  localStorage.setItem(name, encryptedValue);
};

export const getValue = (cname) => {
  try {
    const name = KEY_MAPPER[cname];
    const data = localStorage.getItem(name);

    if (!data) {
      return "";
    }
    const decryptedData = decryptData(data);
    return decryptedData;
  } catch (error) {
    console.error(`Failed to get or decrypt cookie '${cname}':`, error);
    return "";
  }
};