import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function userSignupReducer(state = initialState.user, action) {
  switch (action.type) {
    case types.SIGNUP_INITIATED:
      return {
        signupPending: true,
        error: null,
        signupError: false,
        signupSuccess: false,
        signupNotAllowed: false,
      };
    case types.SIGNUP_INVALID_CREDS:
      return {
        signupPending: false,
        error: action.error,
        signupError: true,
        signupSuccess: false,
        signupNotAllowed: action.signupNotAllowed,
      };
    case types.SIGNUP_FAILURE:
      return {
        signupPending: false,
        error: action.error,
        signupError: true,
        signupSuccess: false,
        signupNotAllowed: false,
      };
    case types.SIGNUP_SUCCESS: {
      return {
        signupPending: false,
        error: null,
        signupSuccess: true,
        response: action.user,
        signupError: false,
        signupNotAllowed: false,
      };
    }
    case types.SIGNUP_OTP_INITIATED:
      return {
        signupPending: true,
        error: null,
        signupError: false,
        signupSuccess: false,
        signupNotAllowed: false,
      };
    case types.SIGNUP_OTP_DONE:
      return {
        signupPending: false,
        error: null,
        signupError: false,
        signupSuccess: false,
        signupNotAllowed: false,
      };
    case types.GET_CONSENT_SUCCESS: {
      return {
        signupPending: false,
        error: null,
        signupSuccess: false,
        consent: action.user.data,
        signupError: false,
        signupNotAllowed: false,
      };
    }

    default:
      return state;
  }
}
