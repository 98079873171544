import { makeStyles } from "@mui/styles";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { AgGridReact } from "ag-grid-react";
import React from "react";
import "./customTable.css";

const useStyles = makeStyles((theme) => ({}));

const CustomTable = ({
  rowData,
  gridOptions,
  onGridReady,
  onSelectionChanged,
  tableHeight = "452px",
}) => {
  const classes = useStyles();
  const _onGridReady = onGridReady
    ? onGridReady
    : (params) => {
        params.api.sizeColumnsToFit();
      };

  return (
    <div
      className="ag-theme-material"
      style={{
        height: tableHeight,
        width: "100%",
      }}
    >
      <AgGridReact
        columnDefs={gridOptions.columnDefs}
        rowData={rowData}
        gridOptions={gridOptions}
        domLayout={gridOptions?.domLayout ?? "normal"}
        pagination={gridOptions?.pagination ?? false}
        suppressPaginationPanel={gridOptions?.suppressPaginationPanel ?? false}
        onPaginationChanged={gridOptions?.onPaginationChanged}
        paginationPageSize={gridOptions.paginationPageSize || 10}
        suppressRowHoverHighlight={
          gridOptions?.suppressRowHoverHighlight ?? false
        }
        onGridReady={_onGridReady}
        defaultColDef={gridOptions?.defaultColDef || {}}
        rowSelection={gridOptions?.rowSelection}
        onSelectionChanged={onSelectionChanged}
      ></AgGridReact>
    </div>
  );
};

export default CustomTable;
