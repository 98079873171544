import { Typography } from "@mui/material";
import React, { useMemo } from "react";
import {
  TABLE_OPTIONS,
  columnDefs,
  formatDate,
} from "../../CustomerRedemption/scheme/scheme.utils";
import CustomTable from "../global/cutomTable/customTable";

const TransactionDetailsComponent = (props) => {
  function getFormattedDate() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0"); // Day with leading zero
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Month with leading zero (January is 0!)
    const yyyy = today.getFullYear();

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthName = monthNames[today.getMonth()];

    // Choose between numeric month or month name based on your preference
    // return `${dd}-${mm}-${yyyy}`;  // Numeric month
    return `${dd}-${monthName}-${yyyy}`; // Month name
  }

  const formattedDate = getFormattedDate();
  const currentDateTime = new Date();
  const schemeEndDateTime = new Date(props?.scheme?.effectivetill);

  const rowData = useMemo(() => {
    return props?.scheme?.transactionsList;
  }, [props?.scheme]);

  return (
    <>
      {/* <div className="transaction-start-end-date">
        <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#1A1A1A" }}>
          Transaction List
        </Typography>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 400,
            color: "#666666",
          }}
        >
          From {formatDate(props?.scheme?.firstTransactionDate)} to{" "}
          {currentDateTime < schemeEndDateTime
            ? formattedDate
            : formatDate(props?.scheme?.effectivetill)}
        </Typography>
      </div> */}
      <div className="transaction-table-container">
        <CustomTable
          rowData={rowData}
          gridOptions={{ ...TABLE_OPTIONS, columnDefs }}
        />
      </div>
    </>
  );
};

export default TransactionDetailsComponent;
