import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

const SloganForm = (props) => {
  const {
    classes,
    consentData,
    handleChange,
    customer,
    setCustomer,
    setOpen,
    handleClickOpen,
    error,
    schemeRegistrationFlag,
    profilePage,
  } = props;
  return (
    <>
      <Grid
        container
        columnSpacing={2}
        className={classes.registrationFormMain}
      >
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="standard-name"
            label="Customer Name"
            className={classes.textField}
            margin="normal"
            name="customerName"
            onChange={handleChange}
            value={customer.customerName}
            error={!!error.customerName}
            helperText={!!error.customerName ? error.customerName : ""}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="standard-number"
            label="Mobile Number"
            className={classes.textField}
            margin="normal"
            name="mobile"
            type="tel"
            inputProps={{
              maxLength: 10,
            }}
            onChange={handleChange}
            disabled={schemeRegistrationFlag || profilePage}
            value={customer.mobile}
            error={!!error.mobile}
            helperText={!!error.mobile ? error.mobile : ""}
          />
        </Grid>

        <Grid item xs={12} md={12} style={{ marginTop: "20px" }}>
          <Grid
            container
            style={{
              outline: "1px solid #ced4da",
              padding: "5px 10px",
              borderRadius: "10px",
            }}
          >
            {consentData?.slogan_placeholder && (
              <Grid item xs={12} md={12} style={{ paddingTop: "15px" }}>
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 400,
                    // color: "#087dc1",
                    paddingLeft: "16px",
                  }}
                >
                  {consentData?.slogan_placeholder || ""}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <TextField
                multiline
                maxRows={5}
                placeholder={consentData?.slogan_placeholder || ""}
                id="standard-slogan"
                label="Slogan"
                className={classes.textField}
                margin="normal"
                name="slogan"
                inputProps={{
                  maxLength: 150,
                }}
                onChange={handleChange}
                value={customer.slogan}
                error={!!error.slogan}
                helperText={!!error.slogan ? error.slogan : ""}
              />
            </Grid>
          </Grid>
        </Grid>

        {!profilePage && (
          <Grid item xs={12}>
            <Box className={classes.consent}>
              <FormControl error={!!error} component="fieldset">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={customer.consentAccepted}
                      onChange={() =>
                        customer.consentAccepted
                          ? setCustomer((prevFormData) => ({
                              ...prevFormData,
                              consentAccepted: !customer.consentAccepted,
                            }))
                          : setOpen(true)
                      }
                      name="consentAccepted"
                      color="primary"
                    />
                  }
                  label={
                    <>
                      I agree to the
                      <span
                        onClick={handleClickOpen}
                        className={classes.consentLink}
                        color="primary"
                      >
                        Terms and Conditions
                      </span>
                    </>
                  }
                />
                {error.consentAccepted && (
                  <Box className={classes.consentError}>
                    <FormHelperText>{error.consentAccepted}</FormHelperText>
                  </Box>
                )}
              </FormControl>
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default SloganForm;
