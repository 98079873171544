import React, { useEffect, useRef } from "react";
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
const moment = require("moment");

const useStyles = makeStyles({
  divider: {
    marginTop: 30,
  },
  consentSubTitle: {
    fontWeight: "bold",
    margin: "20px 0 8px",
  },
  consentDesciption: {
    marginBottom: 12,
  },
});

const CustomerConsent = ({
  open,
  handleClose,
  consentAgree,
  data,
  isCustomerSchemeConsent,
}) => {
  const classes = useStyles();
  const descriptionElementRef = useRef(null);
  const slabVal =
    data.slabType === "volume" ? (
      `${data.slabLimit}L`
    ) : (
      <>
        <span>&#x20B9;</span>
        {data.slabLimit}
      </>
    );
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      aria-labelledby="scroll-dialog-title"
      aria-describedby={`scroll-dialog-description`}
    >
      <DialogTitle id="scroll-dialog-title">
        Terms and Conditions for User Registration
      </DialogTitle>
      {isCustomerSchemeConsent ? (
        <DialogContent dividers={true}>
          <DialogContentText
            id={`scroll-dialog-description`}
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Box>
              <Box className={classes.divider}>
                The terms and conditions are applicable to users registered on{" "}
                {data.disCountType} Scheme valid from{" "}
                {moment.utc(data.validFrom).format("DD-MMM-YYYY hh:mm:ss A")}{" "}
                till {moment.utc(data.validTo).format("DD-MMM-YYYY hh:mm:ss A")}
              </Box>
              <Box className={classes.consentSubTitle}>A. Eligibility:</Box>
              <Box className={classes.consentDesciption}>
                The Terms and Conditions contained herein shall apply to any
                person “User” registering by providing vehicle number and mobile
                number for participating in the {data.disCountType} scheme of
                Bharat Petroleum Corporation Ltd. (“BPCL”) or making
                registration through a gateway service through BPCL's link/app
                /website i.e.{" "}
                <a
                  href={data.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data.website}
                </a>{" "}
                Each User is therefore deemed to have read and accepted these
                Terms and Conditions.
              </Box>
              <Box className={classes.consentSubTitle}>B. Offer Construct:</Box>
              <Box className={classes.consentDesciption}>
                1. BPCL shall hand over the Customer details to a chosen Vendor/
                Service Provider who will deliver gifts to customer on all
                transactions cumulating of fuel over the slab limit of {slabVal}{" "}
                during the offer period per customer identified by vehicle
                number and mobile number
              </Box>
              <Box className={classes.consentDesciption}>
                2. The Customer shall not be eligible for any gift below the
                slab of fuel {slabVal}.
              </Box>
              <Box className={classes.consentSubTitle}>
                C. General Terms & Conditions
              </Box>
              <Box className={classes.consentDesciption}>
                1. The Offer is for the Customers of BPCL and participation in
                this Offer is purely on voluntary basis.
              </Box>
              <Box className={classes.consentDesciption}>
                2. Communications to the Customers of campaign end results, slab
                wise gifts shall be done via SMS.
              </Box>
              <Box className={classes.consentDesciption}>
                3. The customer agrees that BPCL may collect, store, and share
                their personal data with vendors for facilitating the gift
                distribution.
              </Box>
              <Box className={classes.consentDesciption}>
                4. The gift distribution shall be applicable only in India.
              </Box>
              <Box className={classes.consentDesciption}>
                5. Any delays in processing the gifts to the Customers within
                the stipulated timeline will be communicated via SMS on a best
                effort basis to the Customers prior to the deadline(s).
              </Box>
              <Box className={classes.consentDesciption}>
                6. In all matters relating to the Offer, the decision of BPCL
                shall be final and binding in all respects on the Customer.
              </Box>
              <Box className={classes.consentDesciption}>
                7. BPCL reserves the right, at any time, without prior notice
                and without assigning any reason whatsoever to the Customer, to
                add/alter/modify/change or vary all of these terms and
                conditions or to replace, wholly or in part, this Offer by
                another offer, whether similar to this Offer or not, or to
                extend or withdraw the Offer altogether.
              </Box>
              <Box className={classes.consentDesciption}>
                8. BPCL shall be entitled to revoke the Offer without any prior
                notice or intimation to the Customer(s). In such case the
                eligibility or the entitlement shall be appropriately adjusted
                at the sole discretion of BPCL.
              </Box>
              <Box className={classes.consentDesciption}>
                9. BPCL shall not be liable in any manner whatsoever if
                transaction fails due to technical issue
              </Box>
              <Box className={classes.consentDesciption}>
                10. Under no circumstances shall BPCL and/or the Vendor/Service
                Providers, its employees, directors, and its third-party agents
                involved in processing, delivering or managing the Gift
                distribution, be liable for any direct, indirect, incidental,
                special or consequential damages, or any damages whatsoever
              </Box>
              <Box className={classes.consentDesciption}>
                11. The Customer(s) agrees to Indemnify BPCL for or in respect
                of any loss, liability, cost or expenses incurred by BPCL on
                account of breach of any of the above terms and conditions on
                part of Customers(s) or breach of Applicable Laws on part of the
                Customer(s) or any third party liability incurred by BPCL which
                is attributable to the acts, deeds or things on part of the
                Customer(s) or any losses, costs or expenses incurred by the
                bank on account of any fraud or misrepresentation done by the
                Customer or the Customer gaming the system or the Offer
              </Box>
              <Box className={classes.consentSubTitle}>
                D. Governing Law and Jurisdiction:
              </Box>
              <Box className={classes.consentDesciption}>
                All disputes, if any, arising out of or in connection with or as
                a result of above Offer or otherwise relating hereto shall be
                subject to the exclusive jurisdiction of the competent courts /
                tribunals in Mumbai, irrespective of whether courts / tribunals
                in other areas have concurrent or similar jurisdiction.
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      ) : (
        <DialogContent dividers={true}>
          <DialogContentText
            id={`scroll-dialog-description`}
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Box>
              <Box className={classes.divider}>
                The terms and conditions are applicable to users consenting to
                registered with BPCL
              </Box>
              <Box className={classes.consentSubTitle}>A. Eligibility:</Box>
              <Box className={classes.consentDesciption}>
                The Terms and Conditions contained herein shall apply to any
                person “User” registering by consenting to provide vehicle
                number and mobile number to Bharat Petroleum Corporation Ltd.
                (“BPCL”) or making registration through a gateway service
                through BPCL's link/app /website i.e.{" "}
                <a
                  href={data.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data.website}
                </a>{" "}
                Each User is therefore deemed to have read and accepted these
                Terms and Conditions.
              </Box>
              <Box className={classes.consentSubTitle}>
                B. General Terms & Conditions
              </Box>
              <Box className={classes.consentDesciption}>
                1. The data of the Customers/Users of BPCL can be stored and
                shared as per requirement basis.
              </Box>
              <Box className={classes.consentDesciption}>
                2. Communications to the Customers can be made of latest
                campaigns, schemes and information via WhatsApp or SMS.
              </Box>
              <Box className={classes.consentDesciption}>
                3. The customer agrees that BPCL may collect, store, and share
                their personal data with vendors for facilitating the
                information communication and insights gathering.
              </Box>
              <Box className={classes.consentDesciption}>
                4. In all matters, the decision of BPCL shall be final and
                binding in all respects on the Customer.
              </Box>
              <Box className={classes.consentDesciption}>
                5. BPCL reserves the right, at any time, without prior notice
                and without assigning any reason whatsoever to the Customer, to
                add/alter/modify/change or vary all of these terms and
                conditions or to replace, wholly or in part, this Offer by
                another offer, whether similar to this Offer or not, or to
                extend or withdraw the Offer altogether.
              </Box>
              <Box className={classes.consentDesciption}>
                6. BPCL shall not be liable in any manner whatsoever if any
                transaction fails due to technical issue.
              </Box>
              <Box className={classes.consentDesciption}>
                7. Under no circumstances shall BPCL and/or the Vendor/Service
                Providers, its employees, directors, and its third-party agents
                involved in processing, delivering or managing the data, be
                liable for any direct, indirect, incidental, special or
                consequential damages, or any damages whatsoever
              </Box>
              <Box className={classes.consentDesciption}>
                8. The Customer(s) agrees to Indemnify BPCL for or in respect of
                any loss, liability, cost or expenses incurred by BPCL on
                account of breach of any of the above terms and conditions on
                part of Customers(s) or breach of Applicable Laws on part of the
                Customer(s) or any third party liability incurred by BPCL which
                is attributable to the acts, deeds or things on part of the
                Customer(s) or any losses , costs or expenses incurred by the
                bank on account of any fraud or misrepresentation done by the
                Customer or the Customer gaming the system or the Offer.
              </Box>
              <Box className={classes.consentSubTitle}>
                C. Governing Law and Jurisdiction:
              </Box>
              <Box className={classes.consentDesciption}>
                All disputes, if any, arising out of or in connection with or as
                a result of above Offer or otherwise relating hereto shall be
                subject to the exclusive jurisdiction of the competent courts /
                tribunals in Mumbai, irrespective of whether courts / tribunals
                in other areas have concurrent or similar jurisdiction.
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions className={classes.consentAction}>
        <Button variant="outlined" color="error" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="success" onClick={consentAgree}>
          I Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerConsent;
