import { Grid, Button, TextField } from "@mui/material";
import React, { useMemo, useRef } from "react";

const VoucherVerifyOTP = ({
  classes,
  otp,
  handleOtpChange,
  errors,
  handleVerify,
  showTimer,
  spanRef,
  initialSeconds,
}) => {
  const inputRefs = useRef([]);

  const handleChange = (index) => (event) => {
    const value = event.target.value;

    if (/^\d*$/.test(value)) {
      // Allow only numbers
      const newOtp = otp.split("");
      newOtp[index] = value;
      handleOtpChange(newOtp.join(""));

      if (value && index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }

    if (value === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const isVerifyEnabled = useMemo(() => {
    if (otp?.length === 6) return true;
    return false;
  }, [otp]);

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{ padding: "10px 10px", marginTop: "10px" }}
      >
        {Array.from({ length: 6 }).map((_, index) => (
          <Grid item xs="auto" key={index}>
            <TextField
              value={otp[index] || ""}
              onChange={handleChange(index)}
              inputProps={{
                maxLength: 1,
                style: { textAlign: "center" },
                ref: (el) => (inputRefs.current[index] = el),
              }}
              error={!!errors.otp}
              helperText={errors.otp ? "Invalid OTP" : ""}
              variant="outlined"
              size="small"
              sx={{
                width: "40px",
                height: "40px",
                margin: "0 8px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: errors.otp ? "#d32f2f" : "#ccc",
                  },
                  "&:hover fieldset": {
                    borderColor: errors.otp ? "#d32f2f" : "#1976d2",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: errors.otp ? "#d32f2f" : "#1976d2",
                  },
                },
              }}
            />
          </Grid>
        ))}

        <Grid item xs="auto">
          <Button
            variant="contained"
            className={
              isVerifyEnabled ? classes.defaultButton : classes.disabledButton
            }
            onClick={handleVerify}
            disabled={!isVerifyEnabled}
            sx={{ marginLeft: "15px" }}
          >
            Verify
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{ padding: "10px 10px"}}
      >
      {showTimer && (
        <p style={{ marginTop: 20 }}>
          Please wait <span ref={spanRef}>{initialSeconds}</span> minutes to
          resend OTP
        </p>
      )}
      </Grid>
    </>
  );
};

export default VoucherVerifyOTP;
