import { getValue } from "../utils/encryption";

const { fetch: originalFetch } = window;

export const registerFetchInterceptor = () => {
  window.fetchWithInterceptor = (...args) => {
    let [resource, config] = args;

    if (config.headers.append) {
      config.headers.append("application", "ADMIN");
    } else {
      config = {
        ...config,
        headers: { ...config.headers, application: "ADMIN" },
      };
    }

    // send cookies with each req
    config.credentials = "include";

    return originalFetch(resource, config);
  };
};
