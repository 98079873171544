import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
import React from "react";

/**
 * BackDropWrapper component renders the div layout of the application with optional loading indicator and other props.
 *
 * @param {React.ReactNode} props.children - The child components to be rendered within the layout.
 * @param {boolean} props.isLoading - Indicates whether the application is in a loading state.
 * @param {Object} props - Additional props that can be passed to customize the layout.
 * @returns {JSX.Element} - The `BackDropWrapper` component is returning a layout structure with a div
 * content area with a CircularProgress component displayed as a backdrop when isLoading is
 * true.
 */
const BackDropWrapper = ({ children, isLoading, style, className }) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <div>
        {children ?? <></>}
        <Backdrop
          style={{ zIndex: theme.zIndex.drawer + 1, color: "#fff" }}
          open={!!isLoading}
        >
          <CircularProgress sx={{ color: "#087dc1" }} />
        </Backdrop>
      </div>
    </React.Fragment>
  );
};

export default BackDropWrapper;
