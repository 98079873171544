import React, { useState } from "react";
import jsPDF from "jspdf";
import "./styles.css";
import {
  onboarding
} from "./config"
import html2canvas from 'html2canvas';

const { host } = onboarding;
const parseUrl = require("parse-url")
const moment = require("moment");
function GenerateReceipt() {
  const [transaction, setTransaction] = useState({
    amount:'',
    nozzleNo:'',
    product:'',
    quantity:'',
    pumpNo:'',
    name:'',
    receiptID:'',
    txnTime:'',
    txnDate:'',
    vehicleNo:'',
    rsp: "",
    globalTxnID:''
  });
  const [oldTransaction, setOldTransaction] = React.useState(false);
  const [imagePath, setImagePath] = React.useState({});
  const [freeText, setFreeText] = React.useState({});
  const [roDetails, setRoDetails] = useState({
    
    ROName: "",
    address: "",
    dealerMobile: "",
    dealerEmail: "",
    txnID: ""
  });
  const [token,setToken]=useState({})
  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      let url= window.location.href;
      let tokenTransactionData;
      var tokendata=parseUrl(url);
    
      if(tokendata.query && tokendata.query.t ){
      tokenTransactionData=JSON.parse(atob(tokendata.query.t));
      if(tokendata.query ){
        setToken(JSON.parse(atob(tokendata.query.t)));
      }

      if((tokenTransactionData && tokenTransactionData.d)){
        let newdate= new Date(tokenTransactionData.d);
        let now = +new Date();
        if(newdate){
          fetch(` ${host}/ereceipt/manage/free-text`, {
            method: 'GET',
             headers: {
               'Accept': 'application/json',
               'Content-Type': 'application/json',
                   } ,
               })
           .then((response) =>response.json()
           )
           .then((responseJson) => {
           setFreeText(responseJson[0]);
             })
           .catch((error) => {setFreeText({}); console.log(error) });
           fetch(` ${host}/ereceipt/manage/poster`, {
            method: 'GET',  
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
          })
            .then((response) =>response.json()     
            )
            .then((responseJson) => {
              setImagePath(responseJson[0]);
              })
            .catch((error) => {setImagePath({}); console.log(error) });
          let oldday = 60 * 60 * 48 * 1000;
          var compareDatesBoolean = (now - newdate) > oldday;
        setOldTransaction(compareDatesBoolean)
if(compareDatesBoolean===false){
      if(tokenTransactionData && tokenTransactionData.t){
        fetch(`  ${host}/ereceipt/transaction/${tokenTransactionData.t}`, {
          method: 'GET',

          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        })
          .then((response) =>response.json()
   
          )
          .then((responseJson) => {
           if(responseJson[0] && responseJson[0].roid){
            fetch(`${host}/ros/${responseJson[0].roid}`, {
              method: 'GET',    
              headers: {
                'Accept': 'application/json',
               'Content-Type': 'application/json'
              },
            })
              .then((response) =>response.json()       
              )
              .then((responseJson) => {
                setRoDetails(responseJson[0])
                  })
              .catch((error) => { console.log(error) });
           }
       setTransaction(responseJson[0]);
           
          })
          .catch((error) => { console.log(error) });
      }
    
    }
               }
      }
    }
    }
    return () => { isMounted = false };
}, [])

  const generatePDF = () => {
    const input = document.getElementById('ereceipt');
    const inputHeight = input.offsetHeight;
    const inputWidth = input.offsetWidth;
    const pdf = new jsPDF('p', 'px', [inputWidth, inputHeight]);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg', 0.8);
      pdf.addImage(imgData, 'PNG', 0, 0, inputWidth, inputHeight, 'FAST');
      pdf.save(transaction.globalTxnID + ".pdf");
    });
  };
  return (
    <div className={"full-width"}>
   
       {token && !token.t?
 <div style={{margin:'auto'}}>Invalid URL</div>
    :
    <div>
      {oldTransaction===false?
      <span>
      <button onClick={generatePDF} data-testid="download-button" >Download</button>
      <div className="box">
<table>
      <div className="container" style={{marginLeft:'26px',marginBottom:'3px', paddingRight:'60px',paddingTop:'20px'}} id="ereceipt" >
        <div className="row">
        <tr colspan="2">
          <div className="col image-align">          
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/58/Official_BPCL_LOGO_with_tagline_Energising_Lives.pdf/page1-400px-Official_BPCL_LOGO_with_tagline_Energising_Lives.pdf.jpg"
              alt="BPCL Logo"
              className="header-logo"
            />
          </div>
          </tr>
        </div>                                                                     
        <div className="row" style={{fontWeight:'600',fontSize: 'small'}}>
        <tr colspan="2">
          <div className="col image-align">
            {roDetails?
            <b>{`${roDetails.ROName}`}</b>:null}
          </div>
          </tr>
        </div>
        <div className="row ">
        <tr colspan="2">

          <div className="col image-align ">
            {roDetails ?
          <b> {`${roDetails.address.city}`}</b>:null}
          </div>
          </tr>
        </div>
     
        <div className="row " style={{  border: '1px solid grey'}}>

          <div className="col text-align1">Retail Outlet Phone Number</div>
          {roDetails && roDetails.dealerInfo?
          <div className="col text-align" >{`${roDetails.dealerInfo.mobileNo}`}</div>:null}
        </div>
        <div className="row " style={{  border: '1px solid grey'}}>

<div className="col text-align1">Retail Outlet Email ID</div>
{roDetails && roDetails.dealerInfo?
<div className="col text-align" style={{wordBreak: 'break-all'}} >{`${roDetails.dealerInfo.email}`}</div>:null}
</div>
        <div className="row " style={{  border: '1px solid grey'}}>
<div className="col text-align1">GST&nbsp;Number</div>
<div className="col text-align" ></div>
</div>
<div className="row">
        <tr colspan="2">
          <div className="col image-align" style={{fontWeight:'bolder',fontSize: 'x-large'}}>  Fuel E - Receipt</div>
          </tr>
        </div>
        <div className="row " style={{  border: '1px solid grey'}}>
<div className="col text-align1">Receipt&nbsp;ID</div>
{transaction && transaction.receiptID && transaction.receiptID.length?<div className="col text-align" >{`${transaction.receiptID}`}</div>:null}
</div>  <div className="row " style={{  border: '1px solid grey'}}>
<div className="col text-align1">  Transaction&nbsp;Date </div>
<div className="col text-align" >
{moment(new Date( transaction.txnDate)).format("Do MMM  YYYY") }
  </div>
</div>  <div className="row " style={{  border: '1px solid grey'}}>

<div className="col text-align1">Transaction&nbsp;Time</div>

<div className="col text-align" >
{transaction.txnTime}
</div>

</div>  <div className="row " style={{  border: '1px solid grey'}}>

<div className="col text-align1">Customer&nbsp;Name</div>

<div className="col text-align" >{`${transaction.name}`}</div>
</div>  <div className="row " style={{  border: '1px solid grey'}}>

<div className="col text-align1">Vehicle&nbsp;Number</div>
<div className="col text-align" >{`${transaction.vehicleNo}`}</div>
</div>  <div className="row " style={{  border: '1px solid grey'}}>

<div className="col text-align1">Mobile&nbsp;Number</div>
<div className="col text-align" >{`${transaction.mobileNo}`}</div>
</div>  <div className="row " style={{  border: '1px solid grey'}}>

<div className="col text-align1">MPD&nbsp;Number</div>
<div className="col text-align" >{`${transaction.pumpNo}`}</div>
</div>  <div className="row " style={{  border: '1px solid grey'}}>

<div className="col text-align1">Nozzle&nbsp;Number</div>
<div className="col text-align" >{`${transaction.nozzleNo}`}</div>
</div>  <div className="row " style={{  border: '1px solid grey'}}>

<div className="col text-align1">Product&nbsp;Name</div>
<div className="col text-align" >{`${transaction.product}`}</div>
</div>  <div className="row " style={{  border: '1px solid grey'}}>

<div className="col text-align1">Quantity&nbsp;(Ltr)</div>
<div className="col text-align" >{`${transaction.quantity}`}</div>
</div>  <div className="row " style={{  border: '1px solid grey'}}>

<div className="col text-align1">Price&nbsp;(Rs/ Ltr)</div>
<div className="col text-align" >{`${transaction.rsp}`}</div>
</div>  <div className="row " style={{  border: '1px solid grey'}}>

<div className="col text-align1">Amount&nbsp;(Rs)</div>
<div className="col text-align" >{`${transaction.amount}`}</div>
</div>
<div className="row">
        <tr colspan="2">

          <div className="col image-align" style={{fontWeight:'bolder',fontSize: 'x-large', textAlign:'center'}}>          
          {imagePath?.imageData?
 <img src={imagePath.imageData.img} style={{height:'200px', width:'400px'}}alt="Red dot" />:null}
</div>
          </tr>
        </div>
        <div className="row">
        <tr colspan="2">

          <div className="col image-align" style={{fontWeight:'bolder',fontSize: 'x-large', textAlign:'center',  whiteSpace: 'pre-line'}}>          
          {freeText?.freeText?
 <b>{freeText.freeText}</b>:null}
</div>
          </tr>
        </div>
        
        <div className="row">
        <tr colspan="2">

          <div className="col image-align">You may also click on the link to share your feedback/queries.
          <br /><a href="https://www.bharatpetroleum.com/feedback-complaint.aspx">https://www.bharatpetroleum.com/feedback-complaint.aspx </a></div>
          </tr>
        </div><div className="row">
        <tr colspan="2">

          <div className="col image-align" >Note - The Vehicle/Mobile number appearing above is as per the details
furnished by the Customer </div>
          </tr>
        </div><div className="row">
        <tr colspan="2">

          <div className="col image-align" >This is a computer generated e_receipt, Contact Petrol Pump for any
discrepancy or call us @ Toll Free 1800224344 </div>
          </tr>
        </div><div className="row">
        <tr colspan="2">

          <div className="col image-align" >Regd Office :  Bharat Bhavan,&nbsp;4 &nbsp;and&nbsp;6,&nbsp;Currimbhoy Road,&nbsp;Ballard Estate,&nbsp;P.&nbsp;B.&nbsp;No.&nbsp;688,&nbsp;Mumbai&nbsp;&nbsp; -  400001.
          <br />
CIN: L23220MH1952GOI008931
</div>
          </tr>
        </div>
      </div>
          </table>
      </div>
      </span>:<span>Link Expired</span>}
      </div>}
  
    </div>

    
  );
}

export default GenerateReceipt;
