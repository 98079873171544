import React from "react";
import { Grid, Typography } from "@mui/material";

const VoucherCard = ({
  classes,
  voucherCode,
  points,
  validity,
  redemptionStatus,
  onRedeem,
  isDisabled = false,
}) => {
  return (
    <div className={classes.voucherCardContainer}>
      <div className={classes.voucherCardCode}>{voucherCode}</div>

      <div className={classes.voucherCardDetails}>
        <Typography
          variant="body1"
          style={{ color: "#333333", marginBottom: "8px" }}
        >
          <strong>Points:</strong>{" "}
          <span className={isDisabled ? classes.voucherContentDisabled : classes.voucherContent}>{points}</span>
        </Typography>
        <Typography
          variant="body1"
          style={{ color: "#333333", marginBottom: "8px" }}
        >
          <strong>Validity:</strong>{" "}
          <span className={isDisabled ? classes.voucherContentDisabled : classes.voucherContent}>{validity}</span>
        </Typography>
        <Typography
          variant="body1"
          style={{ color: "#333333", marginBottom: "8px" }}
        >
          <strong>Status:</strong>{" "}
          <span className={ isDisabled ? classes.voucherContentDisabled : classes.voucherContent}>{redemptionStatus}</span>
        </Typography>
      </div>

    </div>
  );
};

export default VoucherCard;
