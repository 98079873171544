import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function userRedemptionReducer(
  state = initialState.redemption,
  action
) {
  switch (action.type) {
    case types.INIT_REDEMPTION_LOADING: {
      return { ...state, isLoading: true };
    }
    case types.STORE_REDEMPTION_SUCCESS: {
      return { ...state, data: action.payload || [], isLoading: false };
    }
    case types.STORE_REDEMPTION_ERROR: {
      return { ...state, isLoading: false };
    }
    case types.INIT_REDEEM_VOUCHER_LOADING: {
      return { ...state, isRedeemLoading: true };
    }
    case types.INIT_REDEEM_VOUCHER_SUCCESS: {
      return { ...state, isRedeemLoading: false };
    }
    case types.INIT_REDEEM_VOUCHER__ERROR: {
      return { ...state, isRedeemLoading: false };
    }
    case types.INIT_VOUCHER_LOADING: {
      return { ...state, isLoading: true, voucher: null };
    }
    case types.STORE_VOUCHER_SUCCESS: {
      return { ...state, voucher: action?.payload?.voucher || null, isLoading: false };
    }
    case types.STORE_VOUCHER_ERROR: {
      return { ...state, isLoading: false, voucher: null };
    }
    case types.RESET_VOUCHER: {
      return { ...state, voucher: null, isLoading: false };
    }
    case types.INIT_VOUCHER_HISTORY_LOADING: {
      return { ...state, isLoading: true, voucherHistory: [] };
    }
    case types.STORE_VOUCHER_HISTORY_SUCCESS: {
      return { ...state, voucherHistory: action?.payload?.voucherHistory || [], isLoading: false };
    }
    case types.STORE_VOUCHER_HISTORY_ERROR: {
      return { ...state, isLoading: false, voucherHistory: [] };
    }
    default:
      return state;
  }
}
