import { Backdrop, Button, CircularProgress, Grid, Typography } from "@mui/material";
import React from "react";
import MenuBar from "../../CustomerRedemption/menubar";
import RedeemVoucherModal from "../../components/dealer_dashboard/redeemVoucherModal";
import VoucherHistoryTable from "../../components/dealer_dashboard/voucherHistoryTable";
import { useDashboard } from "./dashboard.hook";
import { useStyles } from "./dashboard.styles";

const Dashboard = () => {
  const classes = useStyles();
  const {
    openRedeemModal,
    setOpenRedeemModal,
    onRedeemModalClose,
    errorObj,
    voucherCode,
    onVoucherCodeChange,
    onValidateVoucher,
    selectedVoucher,
    otpValue,
    handleOtpChange,
    handleOtpVerify,
    txnId,
    onTxnIdChange,
    isVoucherRedeemed,
    voucher,
    voucherHistory,
    initialSeconds,
    spanRef,
    showTimer,
    isLoading,
    setVoucherCode,
    setSelectedVoucher,
    txnAttachment,
    handleFileChange,
    handleRemoveFile,
    fileInputRef
  } = useDashboard();

  return (
    <>
      <MenuBar sideBar title="Dashboard" />
      <div className="dashboard-base-parent-div">
        <Grid container spacing={2} style={{ marginBottom: "20px" }}>
          <Grid item xs={12} sm={6} style={{ alignContent: "flex-end" }}>
            {" "}
            <Typography className={classes.heading}>
              Voucher Redemption History
            </Typography>
          </Grid>

          <Grid item xs={12} sm={true} container justifyContent="flex-end">
            <Button
              variant="contained"
              name="RedeemVoucher"
              className={classes.redeemVoucher}
              onClick={() => {
                setVoucherCode("");
                setSelectedVoucher(null);
                setOpenRedeemModal(true);
              }}
            >
              Redeem Voucher
            </Button>
          </Grid>
        </Grid>

        <VoucherHistoryTable rowData={voucherHistory} />
      </div>

      <RedeemVoucherModal
        open={openRedeemModal}
        onModalClose={onRedeemModalClose}
        classes={classes}
        voucherCode={voucherCode}
        onVoucherSearchChange={onVoucherCodeChange}
        errors={errorObj}
        onValidateVoucher={onValidateVoucher}
        selectedVoucher={selectedVoucher}
        voucher={voucher}
        otpValue={otpValue}
        handleOtpChange={handleOtpChange}
        handleOtpVerify={handleOtpVerify}
        txnId={txnId}
        onTxnIdChange={onTxnIdChange}
        isVoucherRedeemed={isVoucherRedeemed}
        showTimer={showTimer}
        spanRef={spanRef}
        initialSeconds={initialSeconds}
        isLoading={isLoading}
        txnAttachment={txnAttachment}
        handleFileChange={handleFileChange}
        handleRemoveFile={handleRemoveFile}
        fileInputRef={fileInputRef}
      />
       <Backdrop open={!!isLoading || false}>
          <CircularProgress color="inherit" />
        </Backdrop>
    </>
  );
};

export default Dashboard;
