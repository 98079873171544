import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";
import { toPascalCase } from "../../../utils";
import { POI_TYPE } from "./CSR.utils";
import DeleteIcon from "@mui/icons-material/Delete";

const FullRegistrationForm = (props) => {
  const {
    classes,
    handleChange,
    customer,
    setCustomer,
    setOpen,
    handleClickOpen,
    error,
    schemeRegistrationFlag,
    profilePage,
    handlePOITypeChange,
    handlePOIFieldChange,
    handleFileChange,
    handleRemoveFile,
    fileInputRef
  } = props;
  return (
    <>
      <Grid
        container
        columnSpacing={2}
        className={classes.registrationFormMain}
      >
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="standard-name"
            label="Customer Name"
            className={classes.textField}
            margin="normal"
            name="customerName"
            onChange={handleChange}
            value={customer.customerName}
            inputProps={{
              maxLength: 30,
            }}
            error={!!error.customerName}
            helperText={!!error.customerName ? error.customerName : ""}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="standard-number"
            label="Mobile Number"
            className={classes.textField}
            margin="normal"
            name="mobile"
            type="tel"
            inputProps={{
              maxLength: 10,
            }}
            onChange={handleChange}
            disabled={schemeRegistrationFlag || profilePage}
            value={customer.mobile}
            error={!!error.mobile}
            helperText={!!error.mobile ? error.mobile : ""}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            id="standard-PoI-type"
            select
            label="Identification Document"
            className={classes.textField}
            value={customer.PoIDetails.type}
            name="PoIType"
            margin="normal"
            onChange={(e) => {
              handlePOITypeChange(e.target.value);
            }}
            error={!!error?.PoIDetails?.PoIerrorMsg}
            helperText={
              !!error?.PoIDetails?.PoIerrorMsg
                ? error?.PoIDetails?.PoIerrorMsg
                : ""
            }
          >
            {Object.values(POI_TYPE).map((item) => {
              return (
                <MenuItem key={item.key} value={item.key}>
                  {item.label}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>

        {customer?.PoIDetails?.isSelected && (
          <Grid item xs={10} md={5}>
            <Tooltip
              title="If the Driving Licence Number is RJ-13/DLC/12/123456 then please enter RJ-1320120123456 or RJ13 20120123456."
              arrow
            >
              <TextField
                id="standard-DLnumber"
                label={`${
                  POI_TYPE[customer?.PoIDetails?.type]?.label || "POI"
                } Number`}
                placeholder={
                  POI_TYPE[customer?.PoIDetails?.type]?.placeholder ||
                  "DL-1420110012345"
                }
                className={classes.textField}
                margin="normal"
                value={customer.PoIDetails.value}
                inputProps={{ maxLength: 20 }}
                name="poi_no"
                onChange={(e) => {
                  handlePOIFieldChange(e.target.value);
                }}
                error={!!error?.PoIDetails?.value || !!error?.PoIDetails?.file}
                helperText={
                  !!error?.PoIDetails?.value
                    ? error?.PoIDetails?.value
                    : !!error?.PoIDetails?.file
                    ? error?.PoIDetails?.file
                    : customer?.PoIDetails?.fileName
                }
              />
            </Tooltip>
          </Grid>
        )}
        {customer?.PoIDetails?.isSelected && (
          <Grid item xs={1} sx={{ display: "flex", alignItems: "center" }}>
            <>
              <input
                accept=".jpg,.jpeg,.png"
                id="poi_attachment"
                name="poi_attachment"
                type="file"
                onChange={(e) => {
                  handleFileChange(e);
                }}
                ref={fileInputRef} // Attach the ref here
                style={{ display: "none" }}
              />
              {customer.PoIDetails.fileName ? (
                <IconButton
                  onClick={() => handleRemoveFile()}
                  aria-label="remove file"
                >
                  <DeleteIcon />
                </IconButton> ): 
                <label htmlFor="poi_attachment">
                <IconButton component="span">
                  <AttachFileIcon />
                </IconButton>
              </label>
              }
            </>
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <TextField
            id="standard-address"
            label="House No/ Street Address"
            multiline
            maxRows={2}
            className={`${classes.textField} ${classes.textArea}`}
            margin="normal"
            name="address"
            inputProps={{
              maxLength: 30,
            }}
            onChange={handleChange}
            value={customer.address}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="standard-pincode"
            label="Pincode"
            className={classes.textField}
            margin="normal"
            name="pincode"
            type="tel"
            inputProps={{
              maxLength: 6,
            }}
            onChange={handleChange}
            value={customer.pincode}
            error={!!error.pincode}
            helperText={!!error.pincode ? error.pincode : ""}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="standard-city"
            label="City"
            className={classes.textField}
            margin="normal"
            name="city"
            inputProps={{
              maxLength: 30,
            }}
            onChange={handleChange}
            value={toPascalCase(customer?.city)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="standard-state"
            label="State"
            className={classes.textField}
            margin="normal"
            name="state"
            inputProps={{
              maxLength: 30,
            }}
            onChange={handleChange}
            value={toPascalCase(customer?.state)}
          />
        </Grid>
        {!profilePage && (
          <Grid item xs={12}>
            <Box className={classes.consent}>
              <FormControl error={!!error} component="fieldset">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={customer.consentAccepted}
                      onChange={() =>
                        customer.consentAccepted
                          ? setCustomer((prevFormData) => ({
                              ...prevFormData,
                              consentAccepted: !customer.consentAccepted,
                            }))
                          : setOpen(true)
                      }
                      name="consentAccepted"
                      color="primary"
                    />
                  }
                  label={
                    <>
                      I agree to the
                      <span
                        onClick={handleClickOpen}
                        className={classes.consentLink}
                        color="primary"
                      >
                        Terms and Conditions
                      </span>
                    </>
                  }
                />
                {error.consentAccepted && (
                  <Box className={classes.consentError}>
                    <FormHelperText>{error.consentAccepted}</FormHelperText>
                  </Box>
                )}
              </FormControl>
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default FullRegistrationForm;
