import { getValue, setValue } from "./encryption";

const eventsToTrack = ["load", "mousemove", "mousedown", "touchstart", "click", "keypress", "scroll"];

class IdleTimer {
  constructor(config, debounceDelay = 300) {
    this.config = config;
    this.eventHandler = this.debounce(this.updateExpiredTime.bind(this), debounceDelay);
  }

  debounce(func, wait) {
    let timeout;
    return function (...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  async init() {
    const isTimeout = await this.checkIfTimeout();
    if (isTimeout) {
      this._onExpired();
      return;
    }
    this.tracker();
    this.startInterval();
  }

  async updateExpiredTime() {
    const val = Date.now() + this.config.timeout * 1000;
    await setValue(this.config.sessionKey, val.toString());
  }

  async getExpiredTime() {
    const time = await getValue(this.config.sessionKey);
    return time;
  }

  _onExpired() {
    this.config.onExpired && this.config.onExpired();
  }

  async checkIfTimeout() {
    const expTime = await this.getExpiredTime();
    if (!expTime) return false;
    let expiredTime = parseInt(expTime, 10);
    expiredTime = isNaN(expiredTime) ? 0 : expiredTime;
    return expiredTime > 0 && expiredTime < Date.now();
  }

  async startInterval() {
    this.interval && clearInterval(this.interval);
    await this.updateExpiredTime();
    this.interval = setInterval(async () => {
      const isTimeout = await this.checkIfTimeout();
      if (isTimeout) {
        this._onExpired();
        this.cleanup();
      }
    }, 1000);
  }

  tracker() {
    eventsToTrack.forEach((event) => {
      window.addEventListener(event, this.eventHandler);
    });
  }

  cleanup() {
    this.interval && clearInterval(this.interval);
    eventsToTrack.forEach((event) => {
      window.removeEventListener(event, this.eventHandler);
    });
  }
}

export default IdleTimer;